<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 items-center">
        <h1 class="text-xl text-white">Hotels - Chain</h1>
      </div>
    </div>
    <div class="flex flex-col gap-2 rounded pb-4 relative">
      <div
        class="flex p-4 items-center bg-gray-800 justify-between sticky top-0"
      >
        <div class="flex gap-2 items-center text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            chainsFound
          }}</strong>
          Chains
        </div>
        <div class="flex items-center gap-4 text-white">
          <Button @click="showAddChainModal"><img src="/icons/i-plus-circle.svg" /> Add Chain</Button>
          <input
            type="search"
            v-model="search"
            class="bg-gray-700 rounded px-4"
            placeholder="Search..."
          />
        </div>
      </div>
      <div>
        <div v-if="!loading">
          <table
            class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0 text-white"
          >
            <tr class="border-b px-4 border-gray-700">
              <th class="text-white px-4 text-left py-2">Name</th>
              <th class="text-white px-4 text-left">Hotels</th>
              <th class="text-white px-4 text-left">NSOs</th>
              <th class="flex gap-4 px-4 justify-end"></th>
            </tr>
            <tbody class="divide-y px-4 divide-gray-700">
              <ListItem
                v-for="chain in sliceChain"
                :key="chain.id"
                :chain="chain"
                @getData="getData"
              />
            </tbody>
          </table>
          <Pagination
            :max="16"
            :actual="actual"
            :total="filteredChains.length"
            @togglePage="togglePage"
          />
        </div>
        <div v-else>
          <Loading size="text-[30px]" />
        </div>
        
      </div>
    </div>
    <div id="map"></div>
  </div>
  <AddChainModal v-if="chainModal" @close="closeChainModal" />
</template>

<script>
import { ListItem } from "@/components/chain";
import Pagination from "@/components/default/Pagination.vue";
import Loading from "@/components/default/Loading.vue";
import { hotelsApi } from "@/utils/apis/hotelsApi";
import Button from '@/components/ui/atoms/Button.vue';
import AddChainModal from "./AddChainModal.vue";

export default {
  components: {
    ListItem,
    Pagination,
    Loading,
    Button,
    AddChainModal
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    sliceChain() {
      let perPage = 15;
      return this.filteredChains.slice(
        this.actual * perPage,
        this.actual * perPage + perPage
      );
    },
    chainsFound() {
      return this.filteredChains.length;
    },
    filteredChains(){
      if (this.search.length) {
        this.actual = 0;
        return this.chains.filter(
          (chain) => chain.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        );
      } else {
        return this.chains;
      }
    }
  },
  data() {
    return {
      search: "",
      actual: 0,
      range: [...Array(8).keys()],
      loading: false,
      loadingMore: false,
      chains: [],
      report: {},
      chainModal: false,
    };
  },
  watch: {},
  mounted() {
    this.getData();
  },
  methods: {
    closeChainModal(newChainName) {
      this.chainModal = false;
      if (newChainName){
        this.search = newChainName;
        this.getData();
      }
        
    },
    showAddChainModal() {
      this.chainModal = true;
    },
    togglePage(page) {
      this.actual = page;
    },
    async getData() {
      this.loading = true;
      const chainStats = await hotelsApi.listChainStats()
      if(chainStats.error){
        alert("Error fetching chain stats");
        this.loading = false;
        return
      }
      this.chains  = chainStats.data;
      document.title = `Launchpad - Crewfare - Chain`;
      this.loading = false;
    },
  },
};
</script>
