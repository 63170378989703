<template>
  <div v-if="message" class="w-full flex flex-col flex-grow">
    <div class="text-xl text-white gap-4 p-2 flex items-center justify-between">
      <div class="flex flex-col">
        <div class="text-xl text-white p-2 flex items-center justify-between">
          <span class="flex items-center gap-2">
            {{ messageTitle }}
          </span>
        </div>
        <p v-if="isConcession" class="text-sm text-gray-200 px-2">
          Type: {{ message?.title }}
        </p>
      </div>
    </div>
    <div class="flex flex-col flex-grow">
      <div
        ref="messageList"
        class="flex-grow flex flex-col gap-4 flex-grow h-full overflow-auto p-2 chat-holder"
      >
        <ListItem
          v-for="messageChat in messages"
          :message="messageChat"
          :key="messageChat.id"
          :proposal="proposal"
          :userGroupName="userGroupName"
          :recipient="message?.recipient || ''"
        />
      </div>
      <Form @addFile="addFile" @addChat="addChat" :message="message" />
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  query,
  collection,
  where,
  orderBy,
} from "firebase/firestore";
import { Form, ListItem } from "@/components/messages/chat";
import { addChat, addFile } from "@/utils/messages.jsx";
import { messagesApi } from "@/utils/apis/messagesApi";
import { isCrewfareAccount } from "@/utils/account";
import { getProposal } from "@/utils/proposals";
import { MESSAGE_TYPES } from "@crewfare/server-shared/messages";

export default {
  components: {
    ListItem,
    Form,
  },
  computed: {
    userGroupName(){
      const groups = this.$store.state.groups;
      return groups?.find?.(group => group.id === this.userGroupId)?.name || "";
    },
    messageTitle(){
      if(this.isConcession){
        return `${(this.message?.rfpName || this.proposal?.name || "")} - ${this.proposal?.hotel_name || this.hotel?.data()?.name ||""}`
      }
      return this.message?.title || this.hotel?.data()?.name;
    },
    isConcession() {
      return this.message?.type === MESSAGE_TYPES.CONCESSIONS;
    },
    account() {
      return this.$store.state.account;
    },
  },
  data: () => {
    return {
      message: null,
      rfp: null,
      messages: [],
      hotel: null,
      userGroupId: null,
      proposal: null
    };
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.getMessage();
      }
    },
    isConcession(){
      if(this.isConcession){
        console.log("changing type");
        this.$emit("changeMessageType", "CONCESSIONS");
      }
    }
  },
  async mounted() {
    this.getMessage();
  },
  methods: {
    async getMessage() {
      const messageRes = await messagesApi.get(this.$route.params.id);
      if(messageRes.error){
        alert(messageRes.message||"Error getting message");
        return;
      }
      this.message = messageRes.data;
      if (this.message.hotel_id) {
        const hotelRef = doc(firestore, "hotels", this.message.hotel_id);
        this.hotel = await getDoc(hotelRef);
        document.title = `Launchpad - Crewfare - Chat - ${
          this.hotel.data()?.name
        }`;
      } else {
        document.title = `Launchpad - Crewfare - Chat - ${
          this.message.title
        }`;
      }
      await setDoc(doc(firestore, "messages", this.message.id), {
        ...this.message,
        new_message: false,
      });
      if (this.message.proposal_id) {
        this.proposal = await getProposal(this.message.proposal_id);
      }
      if(isCrewfareAccount(this.account)){
        const messageGroupId = this.message.toUserGroup || this.message.initiatorGroupId;
        this.userGroupId = messageGroupId;
        this.$store.commit("setGroupId", messageGroupId);
        const accountId = this.account?.id;
        const isDirect = this.message.accountIds?.[accountId] 
        const isInvolved = this.message.accounts?.includes?.(this.account.email);
        const isMyRFP = this.proposal?.created_by_id == accountId;
        if(!(isDirect || isInvolved || isMyRFP)){
            console.log("This message accounts", this.message.accounts);
            this.$emit("refreshFilteredMine", false);
        }
      }
      this.getData();
    },
    async getData() {
      onSnapshot(
        query(
          collection(firestore, "chat"),
          where("message_id", "==", this.$route.params.id),
          orderBy("created_at", "asc")
        ),
        (doc) => {
          this.messages = doc.docs;
          setTimeout(() => {
            this.$refs.messageList.scrollTop =
              this.$refs.messageList?.scrollHeight || 0;
          }, 200);
        }
      );
    },
    async addChat(content) {
      addChat(this.account, this.message, content);
    },
    async addFile({ url, name }) {
      addFile(this.account, this.message, url, name);
    },
  },
};
</script>
