import { createStore } from 'vuex';
import { saveRfpSession } from '@/utils/rfp.jsx';
import { User } from 'firebase/auth';
import { unique } from '@crewfare/utils';
interface InitialState {
  user: User | null;
  account: any;
  hotel: any;
  search: any;
  selectedHotels: [];
  selectedRFP: any;
  menuStatus: true;
  toast: '';
  mention: '';
  contact: any;
  assignRFP: false;
  redirect: any;
  updateHotels: false;
  messageOpened: any;
  notesOpened: any;
  updateRFP: false;
  hotelImporting: any;
  rfpSession: { id: ''; history: []; field: []; changes: any[] };
  importedHotels: [];
  hotelsIdWithContact: [];
  currentRfp: any;
  groups: {
    selectedId: string;
    data: object[];
    loading: boolean;
  };
  lastUploaded: number;
}
const initialState: InitialState = {
  user: null,
  account: null,
  hotel: null,
  search: null,
  selectedHotels: [],
  selectedRFP: null,
  menuStatus: true,
  toast: '',
  mention: '',
  contact: null,
  assignRFP: false,
  redirect: null,
  updateHotels: false,
  messageOpened: null,
  notesOpened: null,
  updateRFP: false,
  hotelImporting: null,
  rfpSession: { id: '', history: [], field: [], changes: [] },
  importedHotels: [],
  hotelsIdWithContact: [],
  currentRfp: {},
  groups: {
    selectedId: '',
    data: [],
    loading: false,
  },
  lastUploaded: 0,
};

export default createStore({
  state: initialState,
  mutations: {
    setLastUploaded(state) {
      state.lastUploaded = new Date().getTime();
    },
    setGroupLoading(state, data) {
      state.groups.loading = data;
      state.groups.data = [];
    },
    setGroupId(state, data) {
      state.groups.selectedId = data;
    },
    setGroups(state, data) {
      state.groups.data = data;
      state.groups.loading = false;
    },
    setCurrentRfp(state, data) {
      state.currentRfp[data.id] = data;
    },
    setHotelsIdWithContact(state, data) {
      state.hotelsIdWithContact = data;
    },
    importedHotels(state, data) {
      state.importedHotels = data;
    },
    resetRfpSession(state) {
      state.rfpSession = { id: '', history: [], field: [], changes: [] };
      state.currentRfp = {};
    },
    setRfpSession(state, data) {
      state.rfpSession = { ...data.rfpSession };
      state.rfpSession.changes = unique(data.rfpSession.changes);

      saveRfpSession({
        rfp: data.rfp,
        account: data.account,
        rfpSession: data.rfpSession,
      });
    },
    setHotelImporting(state, data) {
      state.hotelImporting = data;
    },
    setUpdateRFP(state, data) {
      state.updateRFP = data;
    },
    setNotesOpened(state, data) {
      state.notesOpened = data;
    },
    setMessageOpened(state, data) {
      state.messageOpened = data;
    },
    setRedirect(state, data) {
      state.redirect = data;
    },
    setSelectedRFP(state, data) {
      state.selectedRFP = data;
    },
    setUpdateHotels(state, data) {
      state.updateHotels = data;
    },
    setMentionMessage(state, data) {
      state.mention = data;
    },
    setSelectedHotels(state, data) {
      state.selectedHotels = data;
    },
    setAssignRFP(state, data) {
      state.assignRFP = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setAccount(state, data) {
      state.account = data;
    },
    setSelectedHotel(state, data) {
      state.hotel = data;
    },
    setContact(state, data) {
      state.contact = data;
    },
    setSearch(state, data) {
      state.search = data;
    },
    setMenuStatus(state, data) {
      state.menuStatus = data;
    },
    setToast(state, data) {
      state.toast = data;
    },
  },
});
