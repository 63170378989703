import { BaseApi, getToken } from './apiFactory';

class ContractsApi extends BaseApi {
  async sign(id: string, body: { signature: string; name: string; title: string }) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}/sign`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });
  }
}

export const contractsApi = new ContractsApi({ name: 'contract' });
