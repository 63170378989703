<template>
  <div class="flex flex-col gap-1 w-full">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex gap-2 items-center">
        <span
          class="material-symbols-rounded text-[16px]"
          :class="checked ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ checked ? "check_box" : "check_box_outline_blank" }}
        </span>
        IATA #:
      </div>

      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at IATA #"
          :id="`${rfp.id}-iata`"
          :rfp="rfp.id"
          :hotel_id="rfp.hotel_id"
          field="IATA"
          text="Comment"
          :passed-proposal="rfp"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </div>
    <input
      :disabled="is_blocked || is_locked"
      type="text"
      v-model="value"
      @blue="save"
      class="w-full bg-gray-700 rounded px-4 print:px-0"
      :class="
        has_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
    />
    <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      value: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
    };
  },
  computed: {
    checked() {
      return this.value === this.rfp.iata;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.value = this.rfp.iata;
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes = checkHasChanges(this.rfp, "IATA", account_type);
        this.is_locked = checkIsLocked(this.rfp, "iata");
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone("IATA", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("IATA", this.rfp);
      this.$emit("refreshData");
    },
    async save() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "IATA",
          before: this.rfp.iata,
          after: this.value || "",
        },
        {
          iata: this.value || "",
        },
        "iata"
      );
      this.$emit("refreshData");
    },
  },
};
</script>
