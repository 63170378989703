import { firestore } from '@/utils/firebase';
import { getDocs, doc, getDoc, setDoc, query, collection, where, addDoc, deleteDoc } from 'firebase/firestore';
import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';
import store from '@/store';
import axios from 'axios';
import { parseCSVLine } from '@crewfare/utils';
import { hotelsApi } from './apis/hotelsApi';
import { accountsApi } from './apis/accountsApi';
import { isValidEmail, stringsKeysCreator } from '@crewfare/server-shared';
import { convertArrayToKeyMappedObjectArrays } from '@crewfare/commons/src/utils';

const getChains = async () => {
  return hotelsApi.listChains().then(_ => _.data || []);
};

const updateHotelContacts = async hotel_id => {
  const hotelRef = doc(firestore, 'hotels', hotel_id);
  const hotelData = await getDoc(hotelRef);
  const qContacts = query(collection(firestore, 'contacts'), where('hotel_id', '==', hotel_id));
  const contacts = await getDocs(qContacts);
  const contactsList = contacts.docs;
  await setDoc(doc(firestore, 'hotels', hotel_id), {
    ...hotelData.data(),
    contacts: contactsList.map(contact => {
      return {
        name: contact.data().name,
        email: contact.data().email,
        phone: contact.data().phone || '',
      };
    }),
  });
};

const exportHotels = async hotelList => {
  let csvFile = 'name,lat,lng,chain,state,rooms,uuid\n';
  for (const hotel of hotelList) {
    const hotelData = hotel.data();
    const name = hotelData.name || '';
    const state = hotelData.state || '';
    const lat = hotelData.lat || '';
    const lng = hotelData.lng || '';
    const rooms = hotelData.rooms || '';
    const chain = hotelData.chain || '';
    const id = hotel.id || '';
    let line = `"${name}",${lat},${lng},${chain},${state},${rooms},${id}\n`;
    csvFile += line;
  }
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  const date = moment().format('YYYY-MM-DD');
  const filename = `launchpad-hotels-${date}.csv`;
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const addHotelContact = async ({ name, email, role = '', phone = '', hotelId }) => {
  if (!name || !email) {
    return contactImports;
  }
  email = email.toLowerCase().trim();
  const qAccounts = query(collection(firestore, 'accounts'), where('email', '==', email));
  let account_id = '';
  const account = await getDocs(qAccounts);
  if (account.empty) {
    const account = await addDoc(collection(firestore, 'accounts'), {
      name,
      email,
      role,
      permissions: '',
      type: 'contact',
      created_at: new Date(),
    });
    account_id = account.id;
  } else {
    account_id = account.docs[0].id;
  }
  await addDoc(collection(firestore, 'contacts'), {
    name,
    email,
    phone,
    hotel_id: hotelId,
    account_id,
    created_at: new Date(),
  });
  return true;
};
let loader;
const convertAddressToGAddress = async addrFull => {
  if (!loader) {
    const apiKey = 'AIzaSyCwDSzKEVg4LGxPAqvcyJTwngTj7OPFJ18';
    loader = new Loader({
      apiKey,
    });
    await loader.load();
    await google.maps.importLibrary('places');
    await google.maps.importLibrary('maps');
  }
  return new Promise((resolve, reject) => {
    const mapUploadMaps = new google.maps.Map(document.getElementById('mapHolder'));
    const serviceMaps = new google.maps.places.PlacesService(mapUploadMaps);
    const search = {
      query: addrFull,
      fields: ['name', 'geometry', 'formatted_address'],
    };
    serviceMaps.findPlaceFromQuery(search, (results, status) => {
      if (status === 'OK') {
        resolve({ error: false, ...results[0] });
      } else {
        resolve({ error: true, message: status });
      }
    });
  });
};

const mapFileToHotels = async (file, expectedColumns) => {
  return new Promise((resolve, reject) => {
    if (file) {
      var reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = async evt => {
        const result = evt.target.result;
        const lines = result.split('\n').filter(line => !!line);
        try {
          const headers = parseCSVLine(lines[0]);
          expectedColumns.forEach((column, index) => {
            if (headers[index].toLowerCase().trim() !== column.toLowerCase().trim())
              throw new Error(`Error on headers: expected ${column} but got ${headers[index]}`);
          });
          const hotels = await Promise.all(
            lines.slice(1).map(async (line, lineIndex) => {
              const data = parseCSVLine(line);
              const addrFull = `${data[2]}, ${data[4]}, ${data[5]}, ${data[6]}`;
              const location = await convertAddressToGAddress(addrFull);
              let lat = 0,
                lng = 0;
              if (!location.error) {
                lat = location.geometry.location.lat();
                lng = location.geometry.location.lng();
              }
              const name = (data[7] || '').trim();
              const email = (data[8] || '').trim();
              if (name && !email) {
                throw new Error(`Error on line ${lineIndex + 2}: missing email for contact ${name}`);
              }
              if (name && !isValidEmail(email)) {
                throw new Error(`Error on line ${lineIndex + 2}: invalid email ${email}`);
              }
              if (email && !name) {
                throw new Error(`Error on line ${lineIndex + 2}: missing name for contact ${email}`);
              }
              const hotel = {
                uid: `${stringsKeysCreator(data[0])}${stringsKeysCreator(addrFull)}`,
                name: (data[0] || '').trim(),
                chain: (data[1] || '').trim(),
                address: (data[2] || '').trim(),
                addrFull: (addrFull || '').trim(),
                country: (data[3] || '').trim(),
                city: (data[4] || '').trim(),
                state: (data[5] || '').trim(),
                zipCode: (data[6] || '').trim(),
                contactName: name,
                contactEmail: email,
                lat,
                lng,
                hasContact: !!name && !!email,
                hasLocationError: !!location.error,
              };
              return hotel;
            }),
          );
          const hotelsMap = convertArrayToKeyMappedObjectArrays(hotels, 'uid');
          resolve({ hotelsMap, headers });
        } catch (e) {
          reject(e);
        }
      };
    } else {
      reject(new Error('No file provided'));
    }
  });
};

const importHotels = async file => {};

const importHotelsLatLng = file => {
  if (file) {
    const apiKey = 'AIzaSyBPAnt1yvpffLhi6YzLIC9r0PuCD_uP_MM';

    var reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = async evt => {
      store.commit('setHotelImporting', true);
      const result = evt.target.result;
      const lines = result.split('\n');
      let linesProccessed = 0;
      let hotelIssues = 0;
      let hotelImports = 0;
      let contactImports = 0;
      const manageContacts = async ({ hotelId, items, index, name }) => {
        const qContacts = query(collection(firestore, 'contacts'), where('hotel_id', '==', hotelId));
        const contacts = await getDocs(qContacts);
        if (contacts.docs.length > 0) {
          if (!contacts.docs.find(contact => contact.data().email === items[8])) {
            contactImports = await addContact({
              name: items[4] || '',
              email: items[5] || '',
              contactImports,
              hotelId,
            });
          }
        } else {
          contactImports = await addContact({
            name: items[4] || '',
            email: items[5] || '',
            contactImports,
            hotelId,
          });
        }
        linesProccessed++;
        await updateHotelContacts(hotelId);
        const importedHotels = [...store.state.importedHotels, name];
        store.commit('importedHotels', importedHotels);
        proccessLine({ index: index + 1 });
      };
      const addContact = async ({ name, email, phone = '', contactImports, hotelId }) => {
        if (!name || !email || email.indexOf('@') < 0) {
          return contactImports;
        }
        email = email.toLowerCase().trim();
        const qAccounts = query(collection(firestore, 'accounts'), where('email', '==', email));
        let account_id = '';
        const account = await getDocs(qAccounts);
        if (account.empty) {
          const account = await addDoc(collection(firestore, 'accounts'), {
            name,
            email,
            permissions: '',
            type: 'contact',
            created_at: new Date(),
          });
          account_id = account.id;
        } else {
          account_id = account.docs[0].id;
        }
        await addDoc(collection(firestore, 'contacts'), {
          name,
          email,
          phone,
          hotel_id: hotelId,
          account_id,
          created_at: new Date(),
        });
        return contactImports + 1;
      };
      const proccessLine = async ({ index }) => {
        if (!lines[index]) {
          store.commit('setHotelImporting', false);
          if (hotelIssues > 0) {
            var blob = new Blob([issues], {
              type: 'text/csv;charset=utf-8;',
            });
            const date = moment().format('YYYY-MM-DD');
            const filename = `launchpad-hotels-${date}-address-issues.csv`;
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, filename);
            } else {
              const link = document.createElement('a');
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          }
          return;
        }
        let items = parseCSVLine(lines[index]);
        const qHotels = query(collection(firestore, 'hotels'), where('name', '==', items[0]));
        const hotels = await getDocs(qHotels);
        let hotelId;
        const resp = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${items[1]},${items[2]}&sensor=true&key=${apiKey}`,
        ).then(call => call.json());
        const addres_components = resp.results[0].address_components;
        let county = '';
        let city = '';
        let zipCode = '';
        let state = '';
        addres_components.forEach(items => {
          if (items.types.includes('postal_code')) zipCode = items.long_name;
          if (items.types.includes('administrative_area_level_1')) state = items.short_name;
          if (items.types.includes('administrative_area_level_2')) county = items.long_name;
          if (items.types.includes('locality')) city = items.long_name;
        });
        const addrFull = resp.results[0].formatted_address;
        if (hotels.docs.length < 1) {
          if (!items[0]) proccessLine({ index: index + 1 });
          const hotelAdd = await addDoc(collection(firestore, 'hotels'), {
            name: items[0],
            addrFull,
            chain: items[3],
            county,
            city,
            state,
            zipCode,
            created_at: new Date(),
            lat: items[1],
            lng: items[2],
          });
          hotelId = hotelAdd.id;
          manageContacts({ hotelId, items, index, name: items[0] });
          hotelImports++;
        } else {
          hotelId = hotels.docs[0].id;
          await setDoc(doc(firestore, 'hotels', hotelId), {
            ...hotels.docs[0].data(),
            name: items[0],
            addrFull,
            chain: items[3],
            county,
            city,
            state,
            zipCode,
            created_at: new Date(),
            lat: items[1],
            lng: items[2],
          });
          manageContacts({ hotelId, items, index, name: items[0] });
        }
      };
      await proccessLine({ index: 1 });
    };
  }
};

const deleteContact = async (hotel_id, contact) => {
  if (!contact?.id) {
    return;
  }
  const deletedRes = await accountsApi.deleteContact(contact.id, hotel_id);
  if (!deletedRes.error)
    store.commit('setToast', {
      content: `<div class='flex flex-col gap-2'>
              <p>Contact removed</p>
            </div>`,
    });
  return deletedRes;
};

const filterHotels = (hotels, filters) => {
  let hotelsAll = [...hotels];
  if (filters.search.length > 0)
    hotelsAll = hotelsAll.filter(hotel => hotel.data().name.toLowerCase().indexOf(filters.search.toLowerCase()) >= 0);
  if (filters.isFavorite) hotelsAll = hotelsAll.filter(hotel => hotel.data().isPartner);
  if (filters.city !== 'All') hotelsAll = hotelsAll.filter(hotel => hotel.data().city === filters.city);
  if (filters.chain !== 'All') hotelsAll = hotelsAll.filter(hotel => hotel.data().chain === filters.chain);
  if (filters.state !== 'All') hotelsAll = hotelsAll.filter(hotel => hotel.data().state === filters.state);
  if (filters.county !== 'All') hotelsAll = hotelsAll.filter(hotel => hotel.data().county === filters.county);
  return hotelsAll;
};

const deleteHotel = async hotel_id => {
  if (confirm('Delete this hotel?')) {
    deleteDoc(doc(firestore, 'hotels', hotel_id));
  }
};

const getHotelsIdsByGroup = async () => {
  const account = store.state.account;
  let qGroup = query(collection(firestore, 'hotel_groups'), where('manager_ids', 'array-contains-any', [account.id]));
  const group = await getDocs(qGroup);
  const hotel_ids = [];
  group.docs.map(group => {
    hotel_ids.push(...group.data().hotels.map(hotel => hotel.id));
  });
  return hotel_ids;
};

const getHotelsIdByChain = async () => {
  const account = store.state.account;
  let qChain = query(collection(firestore, 'chains'), where('manager_ids', 'array-contains-any', [account.id]));

  const chainsDocs = await getDocs(qChain);
  const chains = chainsDocs.docs.map(chain => chain.data().name);
  if (!chains.length) return [];
  let qHotels = query(collection(firestore, 'hotels'), where('chain', 'in', chains));
  const hotels = await getDocs(qHotels);
  return hotels.docs.map(hotel => hotel.id);
};

const listHotelsForRFP = async (search, rfpId, signal = null) => {
  try {
    const response = await hotelsApi.list({ search, rfpId, page: 0, per_page: 20 });
    return response.data.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const downloadAssetFile = async filePath => {
  const fileUrl = `${location.origin}/files/${filePath}`;
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = filePath;
  link.click();
};

export {
  mapFileToHotels,
  importHotelsLatLng,
  deleteContact,
  updateHotelContacts,
  exportHotels,
  importHotels,
  addHotelContact,
  filterHotels,
  deleteHotel,
  getHotelsIdsByGroup,
  getHotelsIdByChain,
  listHotelsForRFP,
  downloadAssetFile,
  getChains,
};
