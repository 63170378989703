function titleCase (str) {
  return (
    str
      .replace(/([A-Z])/g, ' $1')
      .charAt(0)
      .toUpperCase() + str.replace(/([A-Z])/g, ' $1').slice(1)
  )
}

export default titleCase
