/**
 * Takes a string and returns the same string formatted to be lowercase,
 * have spaces removed, and remove any characters that are not numbers or letters.
 * This is useful when creating keys for objects from user-provided data, as it ensures the values are
 * consistent and follow a standard convention.
 * @param str - The string to be processed.
 * @returns The same string but with the values modified to be lowercase,
 * have spaces removed, and remove any characters that are not numbers or letters.
 */
export const stringsKeysCreator = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/\s/g, '')
    .replace(/[^a-z0-9]/g, '');
};
