export enum AccountTypes {
  'Contact' = 'contact',
  'NSO' = 'nso',
  'Admin' = 'admin',
  'Internal' = 'internal',
  'Lead_Manager' = 'lead manager',
  'Group_Manager' = 'group manager',
  'Super_Admin' = 'superadmin',
}

export enum AccountStatus {
  'Active' = 'active',
  'Inactive' = 'inactive',
}
