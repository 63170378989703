<template>
  <div class="flex flex-col gap-4">
    <div class="text-white text-base" :class="{ 'font-bold': isBoldTitle, 'font-medium': !isBoldTitle }">
      {{ title }}
    </div>
    <div class="flex gap-4 items-center">
      <input
        type="text"
        :placeholder="placeholder"
        class="box-border min-w-[255px] rounded-[8px] px-4 py-3 text-sm hover:border-crewfarePurple appearance-none border-2 focus:outline-none font-normal text-white focus:ring-0 focus:outline-none focus:border-transparent w-full"
        :class="currentClass"
        :disabled="isDisabled || isLocked"
        :value="modelValue"
        @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        v-if="!isLocationSearch"
        :readonly="readonly"
      />
      <GMapAutocomplete
        v-if="isLocationSearch"
        class="box-border min-w-[255px] rounded-[8px] px-4 py-3 text-sm hover:border-crewfarePurple appearance-none border-2 focus:outline-none font-normal text-white focus:ring-0 focus:outline-none focus:border-transparent w-full"
        :class="currentClass"
        @place_changed="(place: any) => locationFunction?.(place)"
        :value="modelValue"
      />
      <div
        v-if="iconUrl"
        class="w-6 h-6 bg-contain bg-no-repeat cursor-pointer"
        :style="{ backgroundImage: `url(${iconUrl})` }"
        @click="() => onClickIcon?.()"
      ></div>
    </div>
    <p v-if="isError" class="text-crewfareRed text-xs">{{ errorMessage }}</p>
  </div>
</template>

<script lang="ts">

export default {
  props: {
    title: String,
    isBoldTitle: Boolean,
    placeholder: String,
    isError: Boolean,
    isLocked: Boolean,
    isDisabled: Boolean,
    errorMessage: String,
    iconUrl: String,
    onClickIcon: Function,
    modelValue: String,
    isLocationSearch: Boolean,
    locationFunction: Function,
    readonly: Boolean,
  },
  data() {
    return {
      classes: {
        default: 'bg-crewfareGrey border-transparent',
        error: 'bg-crewfareRed bg-opacity-[4%] border-crewfareRed',
        locked: 'bg-crewfareGrey hover:border-transparent border-transparent',
        disabled:
          'bg-crewfareGrey text-crewfareGrey cursor-not-allowed border-crewfareGreyLighter hover:border-crewfareGreyLighter',
      } as { [key: string]: string },
    };
  },
  computed: {
    currentClass() {
      switch (true) {
        case this.isLocked:
          return this.classes.locked;
        case this.isError:
          return this.classes.error;
        case this.isDisabled:
          return this.classes.disabled;
        default:
          return this.classes.default;
      }
    },
  },
  emits: ['update:modelValue'],
};
</script>
