<template>
  <div>
    <table
      class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0"
    >
      <tr class="border-b border-gray-700">
        <th class="text-white text-left p-4">RFP Name</th>
        <th class="text-white text-left px-4">Hotel</th>
        <th class="text-white text-left px-4">Venue</th>
        <th class="text-white text-left px-4">Event Dates</th>
        <SortableTH
          :sortable="true"
          sortKey="decisionDate"
          @sort="sort"
          class="text-white text-left px-4"
        >
          Due Date
        </SortableTH>
        <SortableTH
          :sortable="true"
          sortKey="updated_at"
          @sort="sort"
          class="text-white text-left px-4"
        >
        Last Updated At
        </SortableTH>
        <th class="text-white text-left px-4"></th>
      </tr>
      <tbody v-if="isNSO" class="divide-y divide-gray-700">
        <ListItemNSO
          v-for="(rfp, index) in rfps"
          :key="index"
          :rfp="rfp"
          @getData="getData"
        />
      </tbody>
      <tbody
        v-if="isGroupManager || isContact"
        class="divide-y divide-gray-700"
      >
        <ListItemGMContact
          v-for="(rfp, index) in rfps"
          :key="index"
          :rfp="rfp"
          @getData="getData"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import ListItemNSO from "./ListItemNSO.vue";
import ListItemGMContact from "./ListItemGMContact.vue";
import SortableTH from "@/components/ui/atoms/SortableTH.vue";
import { SortDirection } from "@crewfare/server-shared"
export default {
  props: ["rfps", "sortBy", "sortDirection"],
  components: { ListItemNSO, ListItemGMContact, SortableTH },
  data() {
    return {
      actual: 0,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    isNSO() {
      return ["nso", "lead manager"].includes(
        this.account.type.toLowerCase()
      );
    },
    isGroupManager() {
      return ["group manager"].includes(this.account.type.toLowerCase());
    },
    isContact() {
      return ["contact"].includes(this.account.type.toLowerCase());
    },
  },
  methods: {
    getData() {
      this.$emit("getData");
    },
    sort(key) {
      let newSortDirection = SortDirection.ASC;
      if(key === this.sortBy){
        newSortDirection = this.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
      } else {
        newSortDirection = SortDirection.ASC;
      }
      this.$emit("sort", key, newSortDirection);
    },
  },
};
</script>
