<template>
  <div class="relative">
    <input
      type="text"
      placeholder="Search"
      class="bg-crewfareGrey border-transparent box-border min-w-[255px] rounded-[8px] px-4 py-3 text-sm hover:border-crewfarePurple appearance-none border-2 focus:outline-none font-normal text-white focus:ring-0"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    />
    <div
      class="absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center justify-center w-5 h-5 bg-contain bg-no-repeat cursor-pointer"
      :style="{ backgroundImage: `url(${iconUrl})` }"
      @click="() => onClickIcon?.()"
    ></div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    iconUrl: String,
    onClickIcon: Function,
    modelValue: String,
  },
  emits: ['update:modelValue'],
};
</script>

