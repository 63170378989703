<template>
  <div
    class="grid grid-cols-[0.2fr,1fr,0.3fr,0.5fr,1fr,0.1fr] items-center gap-4 w-full px-3 py-2 bg-crewfareGrey rounded-lg border border-transparent hover:border-crewfarePurple"
    v-if="hotel"
  >
    <!-- Image -->
    <div class="aspect-square">
      <img
        :src="hotel.image"
        v-if="hotel.image"
        class="object-cover w-[80px] h-[80px] rounded-lg object-fit-cover"
        style="min-width: 80px"
      />
      <div class="w-[80px] h-[80px] bg-crewfareGreyDark flex items-center justify-center rounded-lg" v-else>
        <font-awesome-icon :icon="['fas', 'hotel']" />
      </div>
    </div>
    <!-- Name and address -->
    <div class="flex flex-col gap-1 truncate">
      <p class="text-base font-semibold truncate">{{ hotel.name }}</p>
      <div class="flex items-center gap-1 truncate" v-if="hotel.addrFull">
        <img src="/icons/i-pin.svg" class="aspect-square" />
        <p class="text-sm truncate">{{ hotel.addrFull }}</p>
      </div>
      <p v-if="!(hotel?.location?.lat && hotel?.location?.lon)" class="text-xs text-red-400">Missing Latitude and Longitude</p>
      <div class="flex items-center gap-1 truncate" v-if="isDeleting">Deleting...</div>
    </div>
    <!-- Chain -->
    <p class="text-sm truncate">{{ hotel.chain || 'N/A' }}</p>
    <!-- Rating -->
    <StarRating :model-value="hotel.rating" :read-only="true" />
    <!-- Contacts -->
    <HotelRowContact :contacts="hotel.contacts" />
    <!-- Actions -->
    <div class="relative" @click="handleActions">
      <img src="/icons/i-dots.svg" class="w-4 h-4 cursor-pointer" />
      <div
        v-if="(!isDeleting)&&showActions"
        class="absolute right-5 top-5 flex flex-col gap-2 bg-crewfareGreyDark rounded-lg w-max z-50 cursor-pointer text-crewfareGreen overflow-hidden"
      >
        <div class=" p-2 border-l-4 border-transparent hover:border-l-crewfareGreen" @click="$emit('editHotel', hotel.id)">Edit</div>
        <div class=" p-2 border-l-4 border-transparent hover:border-l-crewfareGreen" @click="removeHotel(hotel.id)">Remove Hotel</div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from '../atoms/StarRating.vue';
import HotelRowContact from './HotelRowContact.vue';

export default {
  props: {
    hotel: Object,
  },
  components: {
    StarRating,
    HotelRowContact,
  },
  methods: {
    handleActions() {
      this.showActions = !this.showActions;
    },
    removeHotel(hotelId) {
      this.isDeleting = true;
      this.$emit('removeHotel', hotelId)
    }
  },
  data() {
    return {
      showActions: false,
      isDeleting: false
    };
  },
  emits: ['editHotel', 'removeHotel'],
};
</script>
