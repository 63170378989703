export enum RECIPIENT_TYPES {
  /**
   * Inline comment shows who can see the messages
   */
  NSO = 'nso', // NSO of the chain + Initator Hotel Contacts Or Initiator group admins/internals
  CHAIN = 'chain', // All hotels within chain + NSO of the chain + Initiator group admins/internals if initiated by group
  CREWFARE = 'crewfare', // Crewfare team ~ Depreceated
  HOTEL = 'hotel', // All Contacts and NSO of hotel chain + Initiator group if admin/internal
  PROPOSAL = 'proposal', // All contacts of hotel and NSO of that hotel chain + group admins/internals
  RFP = 'rfp', // Group Admins/Internal used for Notes
  DIRECT = 'direct', // Direct message to a user
  SOURCING = 'sourcing', // User group admins/internals + Initiator + Initiator's hotel contacts(If initiator is hotel)
}

export interface RecipientInfo {
  recipient: RECIPIENT_TYPES;
  data: {
    proposal_id?: string;
    hotel_id?: string;
    hotel_name?: string;
    chain?: string;
    account_id?: string;
    email?: string;
    userGroupId?: string;
  };
  title: string;
  subtitle: string;
  search: string;
}
