<template>
  <div v-if="!loading">
    <div class="my-4">Hotels by State</div>
    <div class="flex flex-col gap-4">
      <div v-for="(state, index) in stateList" :key="index">
        <router-link
          :to="{ name: 'hotelsListState', params: { state: state.name } }"
          class="border border-white rounded-full px-4 py-2 flex cursor-pointer justify-between items-center hover:bg-white hover:text-black"
        >
          <span class="text-xl"> {{ state.name }} ({{ state.count }}) </span>
          <font-awesome-icon icon="chevron-right" />
        </router-link>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="min-h-[120px] flex items-center justify-center">
      <span class="material-symbols-rounded animate-spin text-[26px]">
        autorenew
      </span>
    </div>
  </div>
</template>

<script>
import { ListItem, ListItemSkeleton, Filters } from "@/components/hotels";
import Pagination from "@/components/default/Pagination.vue";
import { firestore } from "@/utils/firebase";
import { getDocs, query, where, collection, orderBy } from "firebase/firestore";
import { uniqueSortedList } from "@/utils/formatter.jsx";
import Loading from "@/components/default/Loading.vue";
import { search } from "../search";
import { debounce } from "lodash";
import { hotelsApi } from "@/utils/apis/hotelsApi";

export default {
  props: ["search"],
  components: {
    Loading,
    ListItem,
    ListItemSkeleton,
    Filters,
    Pagination,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      actual: 0,
      hotels: [],
      stateList: [],
      loading: false,
      range: [...Array(3).keys()],
      selectedState: null,
      currentSearchCount: 0,
      abortController: new AbortController()
    };
  },
  watch:{
    search: debounce(function(){
      const currentSearchCount = this.currentSearchCount + 1;
      this.currentSearchCount = currentSearchCount;
      this.searchHotels(currentSearchCount);
    }, 1000)
  },
  mounted() {
    this.searchHotels(0)
  },
  methods: {
    async searchHotels(searchCount) {
      console.log("Search started for count ", searchCount)
      this.loading = true;
      this.abortController.abort();
      this.abortController = new AbortController();
      const statesMap = await hotelsApi.listStates(this.search, this.abortController.signal).then(_=>_.data||{});
      const stateList = []
      Object.keys(statesMap).map(state=>{
        stateList.push({
          name:state,
          count: statesMap[state]
        })
      })
      this.stateList = stateList
      if(searchCount == this.currentSearchCount){
        this.loading = false;
      }
    },
  },
};
</script>
