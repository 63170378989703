<template>
  <div class="text-white flex-grow flex flex-col gap-4 px-6 py-3 bg-[#23262F]">
    <div class="flex justify-between">
      <Breadcrumb icon="th-large" :items="['Messages']" />

      <button
        v-if="!newMessage"
        @click="newMessage = true"
        class="border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-black rounded-full px-4 py-2 flex cursor-pointer justify-between items-center gap-2"
      >
        <font-awesome-icon :icon="['fas', 'add']" />
        New Message
      </button>
      <button
        v-else
        @click="newMessage = false"
        class="border border-white rounded-full px-4 py-2 flex cursor-pointer justify-between items-center"
      >
        <span class="material-symbols-rounded text-[16px]">close</span>
        Back
      </button>
    </div>
    <div class="bg-[#141416] p-4 flex flex-col flex-1 gap-4">
      <div v-if="isCrewfare" class="flex bg-[#000000]">
        <button
          @click="toggleType('MESSAGE')"
          class="px-4 py-2 w-full border-b border-[#000]"
          :class="type === 'message' ? 'border-[#6707FD]' : ''"
        >
          Messages
        </button>
        <button
          @click="toggleType('CONCESSIONS')"
          class="px-4 py-2 w-full border-b border-[#000]"
          :class="type === 'Comment on concession' ? 'border-[#6707FD]' : ''"
        >
          Comments On Concessions
        </button>
        <button
          @click="toggleType('NOTES')"
          class="px-4 py-2 w-full border-b border-[#000]"
          :class="type === 'Notes on RFP' ? 'border-[#6707FD]' : ''"
        >
          Notes On RFP
        </button>
      </div>
      <div class="flex flex-grow gap-4">
        <div
          class="p-2 flex-grow min-w-[310px] max-w-[310px] bg-[#23262F] rounded-lg overflow-auto h-[81vh]"
        >
          <div v-if="newMessage" class="flex flex-col gap-2">
            <RecipientsList />
            
          </div>
          <div v-else class="flex flex-col gap-4">
            <input
              type="search"
              placeholder="Search..."
              v-model="search"
              class="bg-gray-800 rounded"
            />
            <div v-if="isInternal" class="flex gap-4">
              <button
                @click="filteredMine = true"
                class="w-full py-2"
                :class="filteredMine ? 'border-b' : 'text-gray-400'"
              >
                Mine
              </button>
              <button
                @click="filteredMine = false"
                class="w-full py-2"
                :class="!filteredMine ? 'border-b' : 'text-gray-400'"
              >
                All
              </button>
            </div>
            <div v-if="loading" class="flex items-center">
              <span v-if="loading" class="material-symbols-rounded animate-spin text-[28px]">
                autorenew
              </span>
            </div>
            <ListItem
              v-else
              v-for="message in messages"
              :key="message.id"
              :message="message"
              @getData="getData"
            />
          </div>
        </div>
        <div class="bg-[#23262F] rounded-lg flex flex-grow">
          <RouterView @changeMessageType = "toggleType" @refreshFilteredMine="refreshFilteredMine" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import Breadcrumb from "@/components/default/Breadcrumb.vue";
import { ListItem, ListItemSkeleton } from "@/components/messages";
import { messagesApi } from "@/utils/apis/messagesApi";
import { debounce } from "@/utils/formatter";
import Loading from "@/components/default/Loading.vue";
import {MESSAGE_TYPES}  from "@crewfare/server-shared/messages";
import { isCrewfareAccount } from "@/utils/account";
import RecipientsList from "@/components/messages/RecipientsList.vue";

export default {
  components: {
    Breadcrumb,
    ListItem,
    RouterView,
    ListItemSkeleton,
    Loading,
    RecipientsList
  },
  data() {
    return {
      newMessage: false,
      loadingMessage: false,
      loading: false,
      messages: [],
      type: null,
      search: "",
      filteredMine: true,
      currentSearchCount: 0,
      abortController: new AbortController(),
    };
  },
  watch: {
    filteredMine() {
      this.getData();
    },
    type(){
      if(this.type === MESSAGE_TYPES.MESSAGE){
        this.filteredMine = true;
      }
      else{
        this.filteredMine = false;
      }
      this.getData();
    },
    selectedGroupId(){
      this.getData();
    },
    newMessage(){
      if(!this.newMessage){
        this.getData();
      }
    }
  },
  computed: {
    isInternal() {
      return isCrewfareAccount(this.account);
    },
    account() {
      return this.$store.state.account;
    },
    isCrewfare() {
      return isCrewfareAccount(this.account)
    },
    selectedGroupId(){
      return this.$store.state.groups.selectedId;
    }
  },
  created() {
    document.title = "Launchpad - Crewfare - Messages";
  },
  async mounted() {
    if (this.isCrewfare) {
      this.type = MESSAGE_TYPES.MESSAGE;
    }
    else{
      this.type = MESSAGE_TYPES.ALL;
    }
    this.getData();
  },
  methods: {
    refreshFilteredMine(isMine){
      this.filteredMine = isMine;
    },
    toggleType(type) {
      if(this.type === type){
        return;
      }
      this.messages = [];
      this.type = MESSAGE_TYPES[type];
      this.getData();
    },
    getData: debounce(async function(){
      const currentSearchCount = this.currentSearchCount + 1;
      this.currentSearchCount = currentSearchCount;
      this.fetchMessages(currentSearchCount);
    }, 500),

    async fetchMessages(searchCount){
      this.loading = true;
      const options = {
        filters: JSON.stringify({
        type: this.type,
        creator: this.filteredMine ? "mine": "all",
      })
      }
      this.abortController.abort();
      this.abortController = new AbortController();
      const response = await messagesApi.list(options, this.abortController.signal);
      if(searchCount !== this.currentSearchCount){
        return;
      }
      if(response.error){
        alert(response.message || "Something went wrong while fetching messages")
      }
      else{
        this.messages = response.data;
      }
      this.loading = false;
    }

  },
};
</script>
