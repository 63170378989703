<template>
  <AdminInternal
    v-if="isCrewfare"
  />
  <HotelContractsList v-else />
</template>

<script>
import AdminInternal from "@/components/files/lists/AdminInternal.vue";
import { isCrewfareAccount } from "@/utils/account";
import HotelContractsList from "@/components/files/lists/HotelContractsList.vue";
export default {
  components: {
    AdminInternal,
    HotelContractsList
  },
  computed: {
    isCrewfare(){
      return isCrewfareAccount(this.account);
    },
    account() {
      return this.$store.state.account;
    },
  },
};
</script>
