<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height text-white">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">Files</h1>
    </div>
    <div class="flex flex-col rounded pb-4 relative">
      <div class="flex p-4 items-center justify-between sticky top-0">
        <div class="text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            total
          }}</strong>
          RFPs
        </div>
      </div>
      <div>
        <table
          class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0"
        >
          <tr class="border-b border-gray-700">
            <th v-if="isNSOAccount" class="text-white text-left p-4">Hotel Name</th>
            <th class="text-white text-left p-4">Event Dates</th>
            <th class="text-white text-left px-4">Cut Off Date</th>
            <th class="text-white text-left px-4">RFP Name</th>
            <th class="text-white text-left px-4">Contract</th>
            <th class="text-white text-left px-4">Travel Coordinator</th>
          </tr>
          <tbody class="divide-y divide-gray-700">
            <HotelContractItem
              v-if="rfps.length > 0"
              v-for="(rfp, index) in rfps"
              :key="index"
              :rfp="rfp"
              :isNSO="isNSOAccount"
              @getData="getData"
            />
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="flex justify-center">
              <Spinner />
      </div>
      <div v-else-if="rfps.length === 0">
        <p class="text-white my-4 text-center">
          There are no RFPs at the moment.
        </p>
      </div>
    </div>
    <Pagination
      :actual="currentPage"
      :max="perPage"
      :total="total"
      @togglePage="togglePage"
    />
  </div>
</template>

<script>
import Pagination from "@/components/default/Pagination.vue";
import HotelContractItem from "@/components/files/HotelContractItem.vue";
import Spinner from "@/components/ui/atoms/Spinner.vue";
import { debounce } from "@/utils/formatter";
import { listProposal } from "@/utils/proposals";
import { ProposalStatusType } from "@crewfare/server-shared/proposals";
import { isNSOAccount } from "@crewfare/server-shared/accounts/accountChecker";

export default {
  components: {
    HotelContractItem,
    Pagination,
    Spinner
  },
  computed: {
    isNSOAccount(){
      return isNSOAccount(this.account);
    },
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      loading: false,
      messages: [],
      rfps: [],
      group: null,
      chain: null,
      perPage: 20,
      currentPage: 0,
      total: 0,
      controller: new AbortController(),
    };
  },
  watch: {
    async account() {
        this.debounceList();
    },
    async $route() {
      this.debounceList();
    },
  },
  async mounted() {
    this.debounceList();
    document.title = `Launchpad - Crewfare - RFPs`;
  },
  methods: {
    togglePage(page) {
      this.currentPage = page;
      this.debounceList();
    },
    debounceList: debounce(async function(){
            this.controller.abort();
            this.controller = new AbortController();
            const currentSignal = this.controller.signal;
            this.getData(currentSignal);
        }, 500),
    async getData(signal = 0) {
      this.loading = true;
      this.rfps = [];
      const proposals = await listProposal({
        filters: JSON.stringify({
          statusType: ProposalStatusType.COMPLETED,
          filterStatus: ProposalStatusType.ALL
        }),
        per_page: this.perPage,
        page: this.currentPage
      })
      if(signal != this.controller.signal){
        console.log("Stale response")
        return;
      }
      this.rfps = proposals.data || [];
      this.total = proposals.total;
      this.loading = false;
    },
  },
};
</script>
