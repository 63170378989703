import { AccountTypes } from '../../interfaces/AccountTypes';
import { AccountDto } from '../../account/dto/update-account.dto';

export const isAccountRestrictedToUserGroups = (account: AccountDto) => {
  return [AccountTypes.Admin, AccountTypes.Internal].includes(
    account.type.toLowerCase() as AccountTypes,
  );
};

export const isNSOAccount = (account: AccountDto) => {
  return account.type.toLowerCase().trim() === AccountTypes.NSO;
};
