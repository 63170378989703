<template>
  <div v-if="originalRFP && rfp">
    <div class="flex justify-between gap-4 items-center">
      <div class="w-full">
        <div
          class="text-2xl flex gap-4"
          :class="has_changes_room_list || isHotel ? 'text-yellow-500' : ''"
        >
          Rooms and Rate:
          <ButtonNotesDialog
            v-if="!isNSO"
            type="Comment on concession"
            :subtitle="rfp.name"
            title="Comments at Room List"
            :id="`${rfp.id}-room-list`"
            :rfp="rfp.id"
            field="Room List"
            :hotel_id="rfp.hotel_id"
            text="Comment"
            :passed-proposal="rfp"
          />
        </div>
        <p
          class="text-sm mt-3"
          :class="has_changes_room_list || isHotel ? 'text-yellow-500' : ''"
        >
          Please complete the required fields highlighted in blue.<br /><br />
          If the requested room quantities are too large for your inventory
          please adjust as you see fit. In the case you would like to offer more
          rooms we welcome that as well!
        </p>
        <small v-if="has_changes_room_list" class="text-yellow-500">
          {{ changes_by }}
        </small>
      </div>
      <div>
        <button
          v-if="rfp.roomListHistoric.length > 1"
          class="green-button whitespace-nowrap"
          @click="show_history_dialog = true"
        >
          Show rates history
        </button>
      </div>
    </div>
    <div v-if="!has_rooms" class="py-2">
      We are still working with our departments to get a final head count but we
      would like to review your proposal of rate and concessions in the
      meantime. If you have a max amount of rooms that you can offer please
      insert your inventory throughout each date so that we can review
    </div>
    <div>
      <div class="flex flex-col">
        <p class="text-xl mt-4">Room types</p>
        <div class="flex">
          <div class="divide-y mt-2 border">
            <div class="flex divide-x text-center text-sm items-center">
              <span
                class="px-4 py-2 min-w-[220px] max-w-[250px] h-[56px] flex flex-col items-center"
              >
                Room Name<br />
                <span class="text-xs">(as seen on your website)</span>
              </span>
              <span
                class="px-4 py-2 w-[220px] h-[56px] flex flex-col justify-center"
              >
                Room Type
              </span>
              <span
                class="px-4 py-2 w-[220px] h-[56px] flex flex-col justify-center"
              >
                Room Configuration
              </span>
              <span
                class="px-4 py-2 w-[250px] h-[56px] flex flex-col justify-center"
              >
                Hotel Room Occupancy Limit
              </span>
              <span
                class="px-4 py-2 w-[250px] h-[56px] flex flex-col justify-center"
              >
                Additional person value
              </span>
            </div>
            <div
              v-for="(room, index) in room_types"
              :key="index"
              class="flex divide-x"
            >
              <input
                type="text"
                :disabled="is_blocked"
                @input="updateDefaultRoomName(index)"
                class="w-[220px] rounded-0 border-0 px-2"
                v-model="room.name"
                placeholder="Room name"
                :class="
                  [
                  checkChangesRoomName(index)
                      ? 'bg-yellow-500'
                      : 'bg-blue-500',
                  ].join(' ')
                "
              />
              <span class="bg-gray-700 w-[220px]">
                <select
                  :disabled="is_blocked"
                  class="px-4 py-2 border-0 w- w-full"
                  v-model="room.type"
                  @change="toggleOccupancy(room)"
                  placeholder="Select a type"
                  :class="
                    [
                      checkChangesType(index, room.type)
                        ? 'bg-yellow-500'
                        : 'bg-blue-500',
                    ].join(' ')
                  "
                >
                  <option value="">Select a type</option>
                  <option>Single</option>
                  <option>Double</option>
                </select>
              </span>
              <span class="bg-gray-700 w-[220px]">
                <select
                  v-if="room.type === 'Single'"
                  :disabled="is_blocked"
                  class="px-4 py-2 border-0 w-full"
                  v-model="room.configuration"
                  @change="saveRoomListAndType"
                  :class="
                    [
                      checkChangesConfiguration(index, room.configuration)
                        ? 'bg-yellow-500'
                        : 'bg-blue-500',
                    ].join(' ')
                  "
                >
                  <option value="">Select a configuration</option>
                  <option>1 King Bed</option>
                  <option>1 Queen Bed</option>
                  <option>1 King Bed with Sleeper Sofa</option>
                  <option>1 Queen Bed with Sleeper Sofa</option>
                  <option>1 Full Size Bed</option>
                  <option>1 Full Size Bed with Sleeper Sofa</option>
                  <option>1 Twin Bed</option>
                  <option>1 Double Bed</option>
                </select>
                <select
                  v-if="room.type === 'Double'"
                  :disabled="is_blocked"
                  class="px-4 py-2 border-0 w-full"
                  v-model="room.configuration"
                  @change="saveRoomListAndType"
                  :class="
                    [
                      checkChangesConfiguration(index, room.configuration)
                        ? 'bg-yellow-500'
                        : 'bg-blue-500',
                    ].join(' ')
                  "
                >
                  <option value="">Select a configuration</option>
                  <option>2 Double Beds</option>
                  <option>Double Queen Beds</option>
                  <option>Double King Beds</option>
                  <option>2 Full Size Beds</option>
                  <option>2 Twin Beds</option>
                  <option>Double Queen Beds with Sleeper Sofa</option>
                  <option>Double King Beds with Sleeper Sofa</option>
                  <option>2 Full Size Beds with Sleeper Sofa</option>
                  <option>2 Twin Beds with Sleeper Sofa</option>
                </select>
                <p
                  v-if="!room.type"
                  class="text-xs flex items-center px-4 h-full bg-blue-500"
                >
                  Please select the room type first
                </p>
              </span>
              <span class="bg-gray-700 w-[250px]">
                <input
                  type="text"
                  :disabled="is_blocked"
                  placeholder="Occupancy"
                  class="px-4 py-2 placeholder:text-gray-700 border-0 w-full"
                  v-model="room.occupancy"
                  @keypress="isNumber($event)"
                  @input="saveRoomListAndType"
                  :class="
                    [
                      checkChangesOccupancy(index, room.occupancy)
                        ? 'bg-yellow-500'
                        : 'bg-blue-500',
                    ].join(' ')
                  "
                />
              </span>
              <span class="bg-gray-700 w-[250px]">
                <input
                  type="text"
                  :disabled="is_blocked"
                  placeholder="Value per person"
                  class="px-4 placeholder:text-gray-800 py-2 border-0 w-full"
                  v-model="room.extra_value"
                  @keypress="isNumber($event)"
                  @input="saveRoomListAndType"
                  :class="
                    [
                      checkChangesExtraValue(index, room.extra_value)
                        ? 'bg-yellow-500'
                        : 'bg-blue-500',
                    ].join(' ')
                  "
                />
              </span>
            </div>
          </div>
        </div>
        <p class="text-xl mt-4">Room Rates</p>
        <div
          v-if="!is_blocked"
          v-show="buld_edit_enabled"
          class="flex gap-4 pt-4 mt-3 print:hidden"
        >
          <div class="flex flex-col w-[220px] items-end justify-center">
            <p>Bulk Edit</p>
            <span class="text-xs text-right">
              Numbers inserted will reflect for each night except the last
            </span>
          </div>
          <div class="flex divide-x divide-gray-500 border border-gray-500">
            <div
              v-for="(room, roomIndex) in room_types"
              :key="roomIndex"
              class="flex flex-col divide-y divide-gray-500"
            >
              <input
                type="text"
                :disabled="is_blocked"
                @blur="updateDefaultRoomName(roomIndex)"
                class="max-w-[180px] rounded-0 border-0 px-2 bg-blue-200"
                :class="
                  !room.name || room.name.length < 1
                    ? 'bg-crewfareBlue placeholder-gray-400'
                    : 'bg-blue-500'
                "
                v-model="room.name"
                placeholder="Room name"
              />
              <input
                type="text"
                :disabled="is_blocked"
                class="max-w-[180px] rounded-0 border-0 bg-gray-700 px-2"
                @input="updateDefaultRoomAmount"
                v-model="room.amount"
                placeholder="Room Amount"
                @keypress="isNumber($event)"
              />
              <div
                v-if="has_rooms"
                class="max-w-[180px] rounded-0 flex bg-gray-700 items-center justify-center"
              >
                <span
                  class="flex items-center justify-center px-4 whitespace-nowrap"
                >
                  {{ default_currency }}
                </span>
                <input
                  type="text"
                  :disabled="is_blocked"
                  @input="updateDefaultRoomValue"
                  placeholder="Rate"
                  class="px-4 print:px-0 pl-0 border-0 bg-transparent w-full text-right"
                  v-model="room.value"
                  @keypress="isNumber($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full py-2">
          <div class="flex gap-2 items-center mt-2">
            <div
              class="text-sm font-bold justify-between items-center flex"
              :class="has_changes_currency && 'text-yellow-500'"
            >
              Currency:
            </div>
            <select
              class="w-full h-[40px] print:appearance-none px-4 print:px-0 rounded"
              :disabled="is_blocked"
              :class="
                has_changes_currency
                  ? 'bg-yellow-500 text-gray-900'
                  : 'bg-gray-700 text-white print:text-black'
              "
              @change="saveCurrency"
              v-model="default_currency"
            >
              <option>Select</option>
              <option>$ USD</option>
              <option>€ EUR</option>
              <option>R$ BRL</option>
              <option>C$ CAD</option>
              <option>£ GBP</option>
              <option>฿ THB</option>
            </select>
            <div class="ml-2">
              <div class="print:hidden">
                <ButtonNotesDialog
                  v-if="!isNSO"
                  type="Comment on concession"
                  :subtitle="rfp.name"
                  :hotel_id="rfp.hotel_id"
                  title="Comments at Currency"
                  :id="`${rfp.id}-currency`"
                  :rfp="rfp.id"
                  field="Currency"
                  text="Comment"
                  :passed-proposal="rfp"
                />
              </div>
            </div>
            <small v-if="has_changes_currency" class="text-yellow-500">
              {{ changes_by }}
            </small>
          </div>
        </div>
        <div class="flex mt-4 max-w-full relative">
          <button
            v-if="!is_blocked"
            class="green-button-small absolute right-0 -top-12"
            :class="buld_edit_enabled && 'green-button-active'"
            @click="buld_edit_enabled = !buld_edit_enabled"
          >
            {{ buld_edit_enabled ? "Hide Bulk Edit" : "Show Bulk Edit" }}
          </button>
          <div>
            <div
              class="flex flex-col min-w-[140px] border border-white border-b-0 border-r-0"
            >
              <div
                class="text-center text-crewfareGreen h-[60px] flex items-center px-2 border-b"
              >
                Room names
              </div>
              <div
                v-for="(room, index) in room_types"
                class="w-full flex justify-center flex-col w-[140px] border-b"
                :class="
                  [
                    has_rooms ? 'min-h-[82px]' : 'min-h-[42px]',
                    checkChangesRoomName(index)
                      ? 'bg-yellow-500'
                      : 'bg-blue-500',
                  ].join(' ')
                "
              >
                <input
                  type="text"
                  :disabled="is_blocked"
                  @blur="updateDefaultRoomName(index)"
                  class="w-full rounded-0 px-2 bg-transparent border-0 flex-grow"
                  v-model="room.name"
                  placeholder="Room name"
                />
              </div>
            </div>
          </div>
          <div class="border w-[30vw] overflow-auto flex flex-1">
            <div
              v-for="(roomItem, index) in room_list"
              :key="index"
              class="flex flex-col divide-y divide-gray-400 border-r border-r-gray-200"
            >
              <div
                class="flex w-full min-w-[200px] max-w-[220px] items-center flex-col justify-center gap-4"
              >
                <span
                  class="w-full min-h-[58px] items-center flex text-center text-sm p-2 p-2 justify-center"
                >
                  {{ roomItem.date }}
                </span>
              </div>
              <div class="flex flex-col">
                <div
                  v-for="(room, roomIndex) in roomItem.rooms"
                  :key="roomIndex"
                  class="flex w-full flex-col divide-y divide-gray-500 border-t border-gray-200"
                >
                  <span
                    class="max-w-[220px] rounded-0 border-0 px-2 flex justify-between items-center"
                    :class="
                      checkChangesRoomAmount(index, roomIndex, room.amount)
                        ? 'bg-yellow-500 text-white'
                        : 'bg-blue-500 text-white print:text-black'
                    "
                  >
                    <span class="text-xs"> Room Quantity: </span>
                    <input
                      type="text"
                      class="flex-1 max-w-[100px] border-0 text-right bg-transparent"
                      :disabled="is_blocked"
                      v-model="room.amount"
                      placeholder="Room Amount"
                      @keypress="isNumber($event)"
                      @blur="saveRoomListAndType"
                      @input="(e) => addRoomAmount(index, roomIndex, e)"
                    />
                  </span>
                  <div
                    v-if="has_rooms"
                    class="max-w-[220px] rounded-0 flex items-center justify-center"
                    :class="
                      [
                        room.value === undefined ||
                        room.value.toString().length < 1
                          ? 'bg-blue-500 text-white'
                          : checkChangesRoomValue(index, roomIndex, room.value)
                          ? 'bg-yellow-500 text-white'
                          : 'bg-blue-500 text-white print:text-black',
                      ].join(' ')
                    "
                  >
                    <span
                      class="flex items-center justify-center whitespace-nowrap"
                    >
                      <span class="text-xs pl-2"> Room Rates:&nbsp;</span>
                      {{ default_currency }}
                    </span>
                    <input
                      type="text"
                      :disabled="is_blocked"
                      class="px-4 print:px-0 pl-0 border-0 w-full bg-transparent text-right"
                      :class="
                        [
                          (room.value === undefined ||
                            room.value.toString().length < 1) &&
                            'placeholder-gray-300',
                        ].join(' ')
                      "
                      v-model="room.value"
                      placeholder="Value"
                      @input="(e) => addRoomRate(index, roomIndex, e)"
                      @keypress="isNumber($event)"
                      @blur="saveRoomListAndType"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HistoryRateDialog
      v-if="show_history_dialog"
      :roomListHistoric="rfp.roomListHistoric"
      @close="show_history_dialog = false"
    />
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import HistoryRateDialog from "@/components/default/HistoryRateDialog.vue";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";
import _ from "lodash";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
    HistoryRateDialog,
  },
  data() {
    return {
      buld_edit_enabled: false,
      has_rooms: false,
      room_list: null,
      room_types: null,
      changes_by: null,
      show_history_dialog: false,
      has_changes_currency: null,
      has_changes_room_list: null,
      field: "RoomList",
      default_currency: "",
      room_list_history_value: null,
      room_type_history_value: null,
    };
  },
  computed: {
    originalRFP() {
      return this.$store.state.currentRfp[this.rfp.id];
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
    isHotel() {
      if (!this.account) return;
      return ["contact"].includes(this.account.type.toLowerCase());
    },
    occupancyDefault() {
      return [
        {
          name: "King",
          type: "Single",
          configuration: "1 King Bed",
          occupancy: "2",
          extra_value: "",
        },
        {
          name: "Double Queen",
          type: "Double",
          configuration: "Double Queen Beds",
          occupancy: "4",
          extra_value: "",
        },
      ];
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    toggleOccupancy(room) {
      if (room.type === "Single") {
        room.occupancy = "2";
      }
      if (room.type === "Double") {
        room.occupancy = "4";
      }
      this.saveRoomTypes();
      this.saveRoomList();
    },
    checkChangesInRoomType(index, key){
      // Take the last two room types history values, last one is the last bid and the one before is the previous bid
      const historicTypes = ( [ ...( this.rfp.roomTypeHistoric || [] ) ] ).slice(-2);
      const previous = historicTypes[0]?.roomTypes || this.occupancyDefault;
      const current = this.originalRFP.roomTypes || this.occupancyDefault;
      if ( !previous )
        return false;
      return previous[index]?.[key] !== current?.[index]?.[key];
    },
    checkChangesInRoomBid(index, roomIndex, key){
      // Take the last two room types history values, last one is the last bid and the one before is the previous bid
      const historicList = ( [ ...( this.rfp.roomListHistoric || [] ) ] ).slice(-2);
      const previous = historicList[0]?.roomList;
      const current = this.originalRFP.roomList;
      if ( !previous )
        return false;
      return previous[index]?.rooms?.[roomIndex]?.[key] !== current?.[index]?.rooms?.[roomIndex]?.[key];
    },
    checkChangesRoomName(index) {
      return this.checkChangesInRoomType(index, "name");
    },
    checkChangesExtraValue(index) {
      return this.checkChangesInRoomType(index, "extra_value");
    },
    checkChangesType(index) {
      return this.checkChangesInRoomType(index, "type");
    },
    checkChangesOccupancy(index) {
      return this.checkChangesInRoomType(index, "occupancy");
    },
    checkChangesConfiguration(index) {
      return this.checkChangesInRoomType(index, "configuration");
    },
    checkChangesRoomAmount(index, roomIndex) {
      return this.checkChangesInRoomBid(index, roomIndex,  "amount");
    },
    checkChangesRoomValue(index, roomIndex) {
      return this.checkChangesInRoomBid(index, roomIndex, "value");
    },
    addRoomRate(day, typeIndex, e) {
      if (day === 0) {
        this.room_types[typeIndex].value = e.target.value;
        this.room_list.map((day, index) => {
          day.rooms.map((room, roomIndex) => {
            if (roomIndex === typeIndex) {
              room.value = e.target.value;
            }
          });
        });
      }
    },
    addRoomAmount(day, typeIndex, e) {
      if (day === 0) {
        this.room_types[typeIndex].amount = e.target.value;
        this.room_list.map((day, index) => {
          day.rooms.map((room, roomIndex) => {
            if (roomIndex === typeIndex) {
              room.amount = e.target.value;
            }
          });
        });
      }
    },
    getData() {
      this.has_rooms = this.rfp.hasRooms;
      if (this.has_rooms === undefined) this.has_rooms = true; 
      this.room_list_history_value = _.cloneDeep(this.rfp.roomList);
      this.room_list = _.cloneDeep(this.rfp.roomList);
      this.room_type_history_value = _.cloneDeep(this.rfp.roomTypes);
      this.room_types = _.cloneDeep(this.rfp.roomTypes);

      if (!this.room_types){
        this.room_types = this.get_room_types();
      }
      else{
        this.room_types = this.room_types.map((room, index) => {
          const amount = this.room_list[0]?.rooms[index]?.amount;
          return {
            ...room,
          };
        });
      }
      this.default_currency = this.rfp.defaultCurrency || "$ USD";
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes_currency = checkHasChanges(
          this.rfp,
          "Currency",
          account_type
        );
        this.has_changes_room_list = checkHasChanges(
          this.rfp,
          "Room List",
          account_type
        );
        if (this.has_changes_room_list || this.has_changes_currency) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    generate_room_types(room_list){
      console.log("Generating room types", room_list);
      if (!room_list) return this.occupancyDefault;
      const types = [];
      room_list.forEach((day) => {
        day.rooms.forEach((room) => {
          if (!types.includes(room.type)) {
            types.push(room.type);
          }
        });
      });
      const typeValues = [];
      types.forEach((type, index) => {
        typeValues.push({
          name: type,
          type: index === 0 ? "Single" : index === 1 ? "Double" : "",
          value: "",
          amount: "",
          currency: "",
          occupancy: index === 0 ? "2" : index === 1 ? "4" : "",
          additional: "",
          configuration:
            index === 0 ? "1 King Bed" : index === 1 ? "Double Queen Beds" : "",
        });
      });
      return typeValues;
    },
    get_room_types() {
      return this.generate_room_types(this.room_list);
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async note_done() {
      noteDone("Room List", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("Room List", this.rfp);
      this.$emit("refreshData");
    },
    updateDefaultRoomValue() {
      if (this.is_blocked) return;
      this.room_list.map((day, index) => {
        day.rooms.map((room) => {
          const type = this.room_types.find(
            (roomType) => roomType.name === room.type
          );
          if (type) {
            room.value = type?.value || "";
          }
        });
      });
      this.saveRoomList();
      this.saveRoomTypes();
    },
    updateDefaultRoomName(indexRoomUpdated) {
      if (this.is_blocked) return;
      this.room_list.map((day) => {
        day.rooms.map((room, indexRoom) => {
          const type = this.room_types.find(
            (roomType, indexType) => indexType === indexRoom
          );
          if (indexRoom === indexRoomUpdated) {
            room.type = type.name;
          }
        });
      });
      console.log("Changing name", indexRoomUpdated, this.room_list);
      this.saveRoomList();
      this.saveRoomTypes();
    },
    saveRoomListAndType(){
      this.saveRoomList();
      this.saveRoomTypes();
    },
    updateDefaultRoomAmount() {
      if (this.is_blocked) return;
      this.room_list.map((day, index) => {
        day.rooms.map((room) => {
          const type = this.room_types.find(
            (roomType) => roomType.name === room.type
          );
          if (type) {
            room.amount = type?.amount || "";
          }
        });
      });
      this.saveRoomList();
      this.saveRoomTypes();
    },
    async saveCurrency() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Currency",
          before: this.rfp.defaultCurrency,
          after: this.default_currency,
        },
        {
          defaultCurrency: this.default_currency,
        },
        "defaultCurrency"
      );
      this.$emit("refreshData");
    },
    async saveRoomList() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Room List",
          before: this.rfp
            .roomList.map(
              (list) =>
                `<span class='text-sm'>Date: ${
                  list.date
                }</span><span class='text-sm'>${list.rooms
                  .map(
                    (room) =>
                      `${room.amount} ${room.type}: ${this.default_currency} ${room.value}`
                  )
                  .join(" - ")}</span>`
            )
            .join(""),
          after: this.room_list
            .map(
              (list) =>
                `<span class='text-sm'>Date: ${
                  list.date
                }</span><span class='text-sm'>${list.rooms
                  .map(
                    (room) =>
                      `${room.amount} ${room.type}: ${this.default_currency} ${room.value}`
                  )
                  .join(" - ")}</span>`
            )
            .join(""),
        },
        {
          roomList: this.room_list,
        },
        "roomList",
        this.room_list_history_value
      );
      this.$emit("refreshData");
    },
    async saveRoomTypes() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Room Type",
          before:
            this.rfp
              .roomTypes?.map(
                (room) =>
                  `<span class='text-sm'>Room: ${room.name}</span>
                <span class='text-sm'>Max occupancy ${room.occupancy} additional per person ${room.additional}</span>`
              )
              .join("") || "",
          after: this.room_types
            .map(
              (room) =>
                `<span class='text-sm'>Room: ${room.name}</span>
                <span class='text-sm'>Max occupancy ${room.occupancy} additional per person ${room.additional}</span>`
            )
            .join(""),
        },
        {
          roomTypes: this.room_types,
        },
        "roomTypes",
        this.room_type_history_value
      );
      this.$emit("refreshData");
    },
  },
};
</script>
