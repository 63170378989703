export enum CREATOR {
  MINE = 'mine',
  ALL = 'all',
}

export enum MESSAGE_TYPES {
  MESSAGE = 'message',
  CONCESSIONS = 'Comment on concession',
  NOTES = 'Notes on RFP',
  ALL = 'all',
}

export interface MessageFilters {
  creator: CREATOR;
  type?: MESSAGE_TYPES;
}
