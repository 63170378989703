export enum ProposalStatus {
  NEW = 'new',
  PROGRESS_SAVED = 'progress saved',
  SIGNED_BY_HOTEL = 'signed by the hotel',
  SIGNED_BY_CREWFARE = 'signed by crewfare',
  HOTEL_COUNTERED = 'hotel countered',
  CREWFARE_COUNTER = 'crewfare counter',
  ACCEPTED_BY_HOTEL = 'accepted by hotel',
  ACCEPTED_BY_CREWFARE = 'accepted by crewfare',
  ACCEPTED_BY_BOTH_SIDES = 'accepted by both sides',
  REOPENED_BY_CREWFARE = 'reopened by crewfare',
  REOPENED_BY_HOTEL = 'reopened by hotel',
  BID_SUBMITTED = 'bid submited',
  UPDATE_BID_TERMS = 'update bid terms',
  ACCEPTED_CONTRACT_UPDATES = 'accepted contract updates',
  SENT = 'sent',
  BID_VIEWED = 'bid viewed',
  BID_OPENED = 'bid opened',
  DECLINED_BY_CREWFARE = 'declined by crewfare',
  DECLINED_BY_HOTEL = 'declined by hotel',
  SIGNED_BY_BOTH_SIDES = 'signed by both sides',
}

export enum ProposalStatusType {
  NEW = 'new',
  PROGRESS = 'progress',
  DECLINED = 'declined',
  COMPLETED = 'completed',
  ALL = 'all',
  REOPENED = 'reopened',
  UNSUBMITTED = 'unsubmitted',
}

export function isContractDownloadable(status: ProposalStatus) {
  return [
    ProposalStatus.SIGNED_BY_BOTH_SIDES,
    ProposalStatus.ACCEPTED_CONTRACT_UPDATES,
    ProposalStatus.ACCEPTED_BY_CREWFARE,
    ProposalStatus.ACCEPTED_BY_HOTEL,
    ProposalStatus.SIGNED_BY_CREWFARE,
    ProposalStatus.SIGNED_BY_HOTEL,
  ].includes(status.toLowerCase() as ProposalStatus);
}

export function getProposalStatusesList(type: ProposalStatusType) {
  switch (type) {
    case ProposalStatusType.NEW:
      return new Set([ProposalStatus.NEW]);
    case ProposalStatusType.UNSUBMITTED:
      return new Set([
        ProposalStatus.SENT,
        ProposalStatus.BID_VIEWED,
        ProposalStatus.BID_OPENED,
      ]);
    case ProposalStatusType.PROGRESS:
      return new Set([
        ProposalStatus.PROGRESS_SAVED,
        ProposalStatus.SIGNED_BY_HOTEL,
        ProposalStatus.SIGNED_BY_CREWFARE,
        ProposalStatus.HOTEL_COUNTERED,
        ProposalStatus.CREWFARE_COUNTER,
        ProposalStatus.ACCEPTED_BY_HOTEL,
        ProposalStatus.ACCEPTED_BY_CREWFARE,
        ProposalStatus.ACCEPTED_BY_BOTH_SIDES,
        ProposalStatus.REOPENED_BY_CREWFARE,
        ProposalStatus.REOPENED_BY_HOTEL,
        ProposalStatus.BID_SUBMITTED,
        ProposalStatus.UPDATE_BID_TERMS,
        ProposalStatus.ACCEPTED_CONTRACT_UPDATES,
        ProposalStatus.SENT,
        ProposalStatus.BID_VIEWED,
        ProposalStatus.BID_OPENED,
      ]);
    case ProposalStatusType.DECLINED:
      return new Set([
        ProposalStatus.DECLINED_BY_CREWFARE,
        ProposalStatus.DECLINED_BY_HOTEL,
      ]);
    case ProposalStatusType.REOPENED:
      return new Set([
        ProposalStatus.REOPENED_BY_CREWFARE,
        ProposalStatus.REOPENED_BY_HOTEL,
      ]);
    case ProposalStatusType.COMPLETED:
      return new Set([ProposalStatus.SIGNED_BY_BOTH_SIDES]);
    case ProposalStatusType.ALL:
      return new Set([
        ProposalStatus.NEW,
        ProposalStatus.PROGRESS_SAVED,
        ProposalStatus.SIGNED_BY_HOTEL,
        ProposalStatus.SIGNED_BY_CREWFARE,
        ProposalStatus.HOTEL_COUNTERED,
        ProposalStatus.CREWFARE_COUNTER,
        ProposalStatus.ACCEPTED_BY_HOTEL,
        ProposalStatus.ACCEPTED_BY_CREWFARE,
        ProposalStatus.ACCEPTED_BY_BOTH_SIDES,
        ProposalStatus.REOPENED_BY_CREWFARE,
        ProposalStatus.REOPENED_BY_HOTEL,
        ProposalStatus.BID_SUBMITTED,
        ProposalStatus.UPDATE_BID_TERMS,
        ProposalStatus.ACCEPTED_CONTRACT_UPDATES,
        ProposalStatus.SENT,
        ProposalStatus.BID_VIEWED,
        ProposalStatus.BID_OPENED,
        ProposalStatus.DECLINED_BY_CREWFARE,
        ProposalStatus.DECLINED_BY_HOTEL,
        ProposalStatus.SIGNED_BY_BOTH_SIDES,
      ]);
  }
}
