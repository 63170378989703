<template>
    <th @click="sort" :class="{'cursor-pointer': sortable}">
    <span class="flex flex-row space-x-1 items-center">
        <span>
            <slot />
        </span>
        <span v-if="sortable">
            <font-awesome-icon :icon="['fas', 'sort']" />
        </span>
    </span>
      
    </th>
  </template>
  <script lang="ts">
  export default{
      props: {
          sortable: {
              type: Boolean,
              default: false,
          },
          sortKey: {
              type: String,
              default: '',
          },
      },
      data() {
  
      },
      methods:{
          sort(){
              this.$emit('sort', this.sortKey);
          }
      }
  }
  </script>
  