<template>
  <div class="flex flex-col gap-1 w-full">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex flex-col gap-2">
        <div class="flex gap-2 items-center">
          <span
            class="material-symbols-rounded text-[16px]"
            :class="checked ? 'text-crewfareGreen' : 'text-white'"
          >
            {{ checked ? "check_box" : "check_box_outline_blank" }}
          </span>
          Cut-Off Date:
        </div>
      </div>
      <div class="flex gap-4 print:hidden items-center">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Cut Off Date"
          :id="`${rfp.id}-cut-off-date`"
          :hotel_id="rfp.hotel_id"
          :rfp="rfp.id"
          field="Cutoff Date"
          text="Comment"
          :passed-proposal="rfp"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </div>
    <div class="text-base">
      Cut-off date
      <input
        :disabled="is_blocked || is_locked"
        v-model="days"
        @blur="save_days"
        class="bg-gray-700 w-[50px] text-right py-1 rounded px-2 print:px-0"
        @keypress="isNumber($event)"
        @input="filterInteger"
        :class="
          has_changes
            ? 'bg-yellow-500 text-gray-900'
            : 'bg-gray-700 text-white print:text-black'
        "
      />
      days prior to event.<br />
      <span class="text-sm">(Date: {{ formatedDate }})</span>
    </div>
    <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
  </div>
</template>

<script>
import moment from "moment";
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";
import { getDateObjFromFirebaseDate } from "@/utils/dateUtils";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      value: "",
      days: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
      startDate: null,
      field: "cutoffDate",
    };
  },
  computed: {
    formatedDate() {
      return moment(this.value).format("MM-DD-YYYY");
    },
    checked() {
      return this.value === getDateObjFromFirebaseDate(this.rfp.cutoffDate);
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
    cutoffDateDays() {
      if (!this.value)
        return `<span class='text-red-400'>Select a cutoffdate</span>`;
      const date = moment(this.startDate);
      const cutoff = moment(this.value);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return `${parseInt(duration.asDays())} days from start date`;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
  },
  watch: {
    rfp(){
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.value = getDateObjFromFirebaseDate(this.rfp.cutoffDate);
      this.startDate = getDateObjFromFirebaseDate(this.rfp.startDate);
      const date = moment(this.startDate);
      const cutoff = moment(this.value);
      const diff = date.diff(cutoff);
      this.days = parseInt(moment.duration(diff).asDays());
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes = checkHasChanges(this.rfp, this.field, account_type);
        this.is_locked = checkIsLocked(this.rfp, this.field);
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    format(date) {
      return moment(date).format("MM-DD-YYYY");
    },
    async note_done() {
      noteDone(this.field, rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded(this.field, rfp);
      this.$emit("refreshData");
    },
    async save_days() {
      const date = moment(this.startDate);
      const newValue = date.subtract(this.days, "days");

      this.value = {
        _seconds: newValue.unix(),
        _nanoseconds: newValue.unix()*1000000000,
      };
      saveField(
        this.rfp,
        {
          label: this.field,
          before: this.rfp.cutoffDate,
          after: {...this.value},
        },
        {
          cutoffDate: {...this.value},
        },
        "cutoffDate"
      );
      this.$emit("refreshData");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (!(charCode >= 48 && charCode <= 57)) {
        evt.preventDefault();
      } else {
        console.log(
          charCode,
          typeof charCode,
          "Char code doesn't match cond",
          charCode > 31,
          charCode < 48 || charCode > 57,
          charCode !== 46
        );
        return true;
      }
    },
    filterInteger(evt) {
      this.days = evt.target.value.replace(/\D/g, "");
    },
  },
};
</script>
