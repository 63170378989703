<template>
  <div v-if="account" class="text-white px-6 py-3 bg-crewfareGreyDark">
    <div class="flex flex-col gap-6">
      <div class="flex items-center gap-3">
        <img src="/icons/i-hotel.svg" class="h-7 w-7" />
        <div class="text-2xl font-bold">Hotels</div>
      </div>
      <div class="flex items-center justify-between">
        <Search v-model="search" :icon-url="'/icons/i-magnifier.svg'" />
        <Button @click="showModalAndCheck"><img src="/icons/i-plus-circle.svg" /> Add Hotels</Button>
      </div>
    </div>
    <div class="flex flex-col rounded pb-4 relative mt-4">
      <Filters v-if="selectedState && hotels.length > 0" :filteredHotels="filteredHotels" @filter="filter" />
      <RouterView
        :search="search"
        :actual="actual"
        :loading="loading"
        @editHotel="editHotel"
        @togglePage="togglePage"
        @toggleState="toggleState"
      />
    </div>
    <div id="mapHolder"></div>
    <Upload v-if="showModal" @close="closeModal" :editHotelId="editHotelId" :is-nso="isNso" :chain="chain" />
  </div>
</template>

<script>
import Breadcrumb from '@/components/default/Breadcrumb.vue';
import Pagination from '@/components/default/Pagination.vue';
import { Filters, ListItem, ListItemSkeleton } from '@/components/hotels';
import Upload from '@/components/hotels/Upload.vue';
import Button from '@/components/ui/atoms/Button.vue';
import Search from '@/components/ui/atoms/Search.vue';
import { isNsoAccount } from '@/utils/account';
import { hotelsApi } from '@/utils/apis/hotelsApi';
import { firestore } from '@/utils/firebase';
import { exportHotels } from '@/utils/hotel.jsx';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from 'flowbite-vue';

export default {
  components: {
    Breadcrumb,
    ListItem,
    ListItemSkeleton,
    Filters,
    Pagination,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    Upload,
    Button,
    Search,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    hotelImporting() {
      return this.$store.state.hotelImporting;
    },
    isNso() {
      return isNsoAccount(this.account);
    },
  },
  data() {
    return {
      showModal: false,
      importing: false,
      actual: 0,
      loading: false,
      hotels: [],
      filteredHotels: [],
      stateList: [],
      selectedState: null,
      file: null,
      type: null,
      search: '',
      editHotelId: null,
    };
  },
  methods: {
    toggleState(state) {
      this.selectedState = state;
    },
    closeModal() {
      this.showModal = false;
      this.editHotelId = null;
    },
    async showModalAndCheck() {
      if (this.isNso) {
        this.chain = await this.getUserChain();
      }
      this.showModal = true;
    },
    togglePage(page) {
      this.actual = page;
    },
    async exportHotels() {
      const qHotels = query(collection(firestore, 'hotels'), orderBy('city'));
      const hotels = await getDocs(qHotels);
      exportHotels(hotels.docs);
    },
    async editHotel(hotelId) {
      this.editHotelId = hotelId;
      if (this.isNso) {
        this.chain = await this.getUserChain();
      }
      this.showModal = true;
    },
    async getUserChain() {
      const chains = await hotelsApi.listAccountChains().then(res => res.data);
      return chains[0].name;
    },
  },
};
</script>
