<template>
  <div
    v-if="message"
    class="flex"
    :class="mine ? 'justify-end' : 'justify-start'"
  >
    <div
      class="rounded px-3 py-1"
      :class="mine ? 'bg-green-600 text-white' : 'bg-gray-700 text-white'"
    >
      <p class="text-xs font-bold mb-1">{{ message.data().created_name }}</p>
      <div
        class="!text-white message-content"
        v-html="message.data().text"
      ></div>
      <p class="text-xs flex items-center gap-4 capitalize text-gray-400 mt-1">
        {{ formated }}
        <button
          @click="setReply(message.data().text)"
          class="text-[14px] material-symbols-rounded hover:text-crewfarePurple"
        >
          reply
        </button>
        <button
          v-if="mine"
          @click="removeMessage"
          class="text-[14px] material-symbols-rounded text-white hover:text-red-500"
        >
          delete
        </button>
        <span v-if="chatTo">To: {{ chatTo }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { firestore } from "@/utils/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { RECIPIENT_TYPES } from "@crewfare/server-shared";
import { isSourceUser } from "@crewfare/server-shared/accounts/userTypesUtil"

export default {
  props: ["message", "proposal", "recipient", "userGroupName"],
  computed: {
    chatTo(){
      const isMessageByHotel = this.message?.data?.()?.isHotel;
      if(!isMessageByHotel){
        return "";
      }
      if(!isSourceUser(this.account)){
        return ""
      }
      if(this.proposal){
        return `${this.proposal.created_by}`;
      }
      if(this.recipient === RECIPIENT_TYPES.DIRECT){
        return "";
      }
      return `${this.userGroupName}`;
    },
    formated() {
      if (!this.message.data().created_at) return "";
      const created_at = moment(this.message.data().created_at.toDate());
      return created_at.fromNow();
    },
    account() {
      return this.$store.state.account;
    },
    mine() {
      return this.message.data().created_id === this.account.id;
    },
  },
  methods: {
    removeMessage() {
      if (!confirm("Delete this message?")) return;
      deleteDoc(doc(firestore, "chat", this.message.id));
    },
    setReply(content) {
      content = content.replace(/text-gray-900/g, "text-gray-300 my-2");
      this.$store.commit(
        "setMentionMessage",
        `<div class="text-gray-300 text-sm px-2 rounded py-1 border border-gray-400">Reply to:<br/>${content}</div>`
      );
    },
  },
};
</script>
