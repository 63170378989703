<template>
  <div class="flex flex-col gap-2 w-full">
    <div class="flex flex-col gap-1">
      <div v-if="hasContacts">
        <div class="text-base truncate">{{ contacts[0].name }}</div>
        <div class="text-sm truncate">{{ contacts[0].email }}</div>
      </div>
      <div v-else>
        <div class="text-base truncate">No Contact</div>
      </div>
      <div v-if="hasContacts" class="flex gap-1 items-center cursor-pointer select-none" @click="toggleContacts">
        <img :src="contactArrowSource" class="w-4 h-4" />
        <div class="text-sm truncate"><span class="text-crewfareGreen">{{ contacts.length }}</span>{{ ` Contact${addPlural}` }}</div>
      </div>
      <div v-if="showContacts" class="flex flex-col gap-1">
        <div
          v-for="(contact, index) in contacts"
          :key="index"
          class="text-sm truncate"
        >
          <div>{{ contact.name }}</div>
          <div>{{ contact.email }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contacts: Array,
  },
  data() {
    return {
      showContacts: false,
    };
  },
  computed: {
    hasContacts() {
      return this.contacts && this.contacts.length > 0;
    },
    addPlural() {
      return this.contacts && this.contacts.length > 1 ? 's' : '';
    },
    contactArrowSource() {
        return this.showContacts ? '/icons/i-arrow-down.svg' : '/icons/i-arrow-right.svg';
    }
  },
  methods: {
    toggleContacts() {
      this.showContacts = !this.showContacts;
    },
  },
};
</script>
