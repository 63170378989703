<template>
  <tr>
    <td class="px-4">
      <div class="flex items-center gap-4">
        <button
          @click="togglePartner"
          class="top-4 right-4 material-symbols-rounded text-crewfareGreen"
          :class="isPartner ? 'material-symbols-filled' : ''"
        >
          star
        </button>
        <div class="flex justify-center my-2">
          <div
            class="h-[90px] w-[90px] rounded-lg overflow-hidden bg-gray-600 flex items-center justify-center"
          >
            <img
              v-if="hotel.image"
              :src="hotel.image"
              class="h-[90px] !max-w-none duration-500 rounded-full"
            />
            <font-awesome-icon v-else :icon="['fas', 'hotel']" />
          </div>
        </div>
        <p class="font-bold">{{ hotel.name }}</p>
      </div>
    </td>
    <td class="px-4">
      {{ chain || '-' }}
    </td>
    <td class="px-4">
      {{ rooms || '-' }}
    </td>
    <td class="px-4">
      <div class="flex items-center">
        <div class="flex gap-2 w-full justify-between">
          <div>
            <router-link
              :to="{ name: 'hotelsContactsList', params: { id: hotel.id } }"
            >
              <div v-if="contactsCount > 0" class="flex flex-col gap-2">
                <p class="text-sm">
                  {{ hotel.contacts[0].name }}<br />
                  <span class="text-xs truncate block"
                    >&lt;{{
                      hotel.contacts[0].email.toLowerCase().trim()
                    }}&gt;</span
                  >
                </p>
                <p class="text-xs" v-if="contactsCount > 1">
                  +{{ contactsCount - 1 }} contacts
                </p>
              </div>
              <div v-else>
                <p class="text-sm">No contacts</p>
              </div>
            </router-link>
          </div>
          <div class="relative">
            <dropdown-menu>
              <template #trigger>
                <button
                  class="inline-flex relative items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  type="button"
                >
                  <span class="material-symbols-rounded">{{
                    options ? "close" : "more_vert"
                  }}</span>
                </button>
              </template>
              <template #body>
                <FwbListGroup
                  class="absolute top-[40px] right-0 z-30 w-[240px] dark:bg-gray-700 bg-gray-700"
                >
                  <FwbListGroupItem class="!px-0 !py-0">
                    <router-link
                      :to="{ name: 'hotelEdit', params: { id: hotel.id } }"
                      class="w-full text-crewfareGreen text-[18px] flex items-center px-4 py-2 px-4 hover:bg-crewfareGreen hover:text-gray-900"
                    >
                      Edit
                    </router-link>
                  </FwbListGroupItem>
                  <FwbListGroupItem class="!px-0 !py-0">
                    <button
                      @click="rfpDialog = true"
                      class="w-full text-crewfareGreen text-[18px] flex items-center px-4 py-2 px-4 hover:bg-crewfareGreen hover:text-gray-900"
                    >
                      Add to existing RFP
                    </button>
                  </FwbListGroupItem>
                  <FwbListGroupItem
                    v-if="hotel.status_history"
                    class="!px-0 !py-0"
                  >
                    <button
                      @click="showStatusHistoryDialog = true"
                      class="w-full text-crewfareGreen text-[18px] flex items-center px-4 py-2 px-4 hover:bg-crewfareGreen hover:text-gray-900"
                    >
                      Hotel History
                    </button>
                  </FwbListGroupItem>
                  <FwbListGroupItem class="!px-0 !py-0">
                    <button
                      @click="sendMessage"
                      class="w-full text-crewfareGreen text-[18px] flex items-center px-4 py-2 px-4 hover:bg-crewfareGreen hover:text-gray-900"
                    >
                      <span
                        v-if="loadingMessage"
                        class="animate-spin material-symbols-rounded"
                      >
                        autorenew
                      </span>
                      <span v-else class="text-[18px]">Send a message</span>
                    </button>
                  </FwbListGroupItem>
                  <FwbListGroupItem class="!px-0 !py-0">
                    <button
                      @click="removeHotel"
                      class="w-full text-red-400 text-[18px] flex items-center px-4 py-2 px-4 hover:bg-red-400 hover:text-white"
                    >
                      Remove hotel
                    </button>
                  </FwbListGroupItem>
                </FwbListGroup>
              </template>
            </dropdown-menu>
          </div>
        </div>
      </div>
    </td>
    <StatusHistoryDialog
      v-if="showStatusHistoryDialog"
      :rfp="hotel.id"
      @hideStatusHistoryDialog="showStatusHistoryDialog = false"
      :status_history="hotel.status_history"
    />
    <RfpDialog v-if="rfpDialog" :hotel="hotel" @close="rfpDialog = false" />
  </tr>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { setDoc, deleteDoc, doc } from "firebase/firestore";
import { sendHotelMessage } from "@/utils/messages.jsx";
import StatusHistoryDialog from "@/components/rfps/hotels/StatusHistoryDialog.vue";
import RfpDialog from "@/components/hotels/RfpDialog.vue";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";

let clickEvent;

export default {
  props: ["hotel"],
  components: {
    StatusHistoryDialog,
    RfpDialog,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
  },
  data() {
    return {
      options: false,
      rfpDialog: false,
      showStatusHistoryDialog: false,
      selected: false,
      contacts: null,
      loading: false,
      rooms: null,
      chain: null,
      isPartner: false,
      addr: null,
      loadingMessage: false,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    contactsCount() {
      if (!this.hotel.contacts) return 0;
      return this.hotel.contacts.length;
    },
  },
  mounted() {
    this.isPartner = this.hotel.isPartner || false;
    this.rooms = this.hotel.rooms || false;
    this.chain = this.hotel.chain || false;
  },
  methods: {
    toggleOptions() {
      this.options = !this.options;
    },
    async togglePartner() {
      this.isPartner = !this.isPartner;
      await setDoc(doc(firestore, "hotels", this.hotel.id), {
        ...this.hotel,
        isPartner: this.isPartner,
      });
    },
    async sendMessage() {
      this.loadingMessage = true;
      await sendHotelMessage(this.hotel, this.account);
      this.loadingMessage = false;
    },
    async   removeHotel() {
      if (!confirm("Delete this hotel?")) {
        return;
      }
      deleteDoc(doc(firestore, "hotels", this.hotel.id));
      this.$emit("getData");
    },
  },
};
</script>
