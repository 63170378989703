import store from '@/store';
import { accountsApi } from './apis/accountsApi';
import { AccountTypes } from '@/enums/AccountTypes';

const updateAccount = async values => {
  const accountId = store.state.account.id;
  const updated = await accountsApi.update(accountId, values);
  if (updated.error) {
    alert('Error updating account');
    return;
  }
  const updatedAccountData = updated.data;
  store.commit('setAccount', updatedAccountData);
};

const isCrewfareAccount = account => {
  const crewfareTypes = [AccountTypes.Super_Admin, AccountTypes.Admin, AccountTypes.Internal];
  const currentAccountType = account.type.toLowerCase();
  return crewfareTypes.includes(currentAccountType);
};

const isNsoAccount = account => {
  const nsoTypes = [AccountTypes.NSO];
  const currentAccountType = account.type.toLowerCase();
  return nsoTypes.includes(currentAccountType);
};

export { updateAccount, isCrewfareAccount, isNsoAccount };
