<template>
  <tr v-if="rfp">
    <td class="text-sm p-4 pb-6 min-w-[300px]">
      <router-link
        :to="{ name: 'rfpsHotels', params: { id: rfp.id } }"
        class="flex flex-col gap-2 items-start hover:text-sky-400"
      >
        <span class="text-base">{{ rfp.name || "New RFP" }}</span>
        <span class="text-xs">{{ rfp.event_name || "-" }}</span>
        <span v-html="statusPill"></span>
      </router-link>
    </td>
    <td class="text-sm p-4 pb-6">
      <div class="flex flex-col gap-2">
        <span class="text-base">{{ rfp.venue || "-" }}</span>
      </div>
    </td>
    <td class="text-sm p-4 pb-6 min-w-[200px]">
      <div>
        <span class="text-[#777E90] mr-2">From</span>
        <span class="font-medium">{{ formatedStartDate }}</span>
      </div>
      <div>
        <span class="text-[#777E90] mr-2">To</span>
        <span class="font-medium">{{ formatedEndDate }}</span>
      </div>
    </td>
    <td class="text-sm p-4 pb-6 min-w-[200px]">
      <div v-if="loading" class="flex items-center justify-center">
        <span class="material-symbols-rounded animate-spin text-[20px]">
          autorenew
        </span>
      </div>
      <div
        v-else
        class="flex flex-col gap-2 cursor-pointer w-full"
        @click="showRoomNumbersDialog = true"
      >
        <div class="w-full flex justify-between">
          <span class="text-xs text-[#777E90] font-bold">Hotels Added</span>
          <span class="text-white text-right">{{ total }}</span>
        </div>
        <div class="w-full flex gap-4 justify-between">
          <span class="text-xs text-[#777E90] font-bold">Hotels Signed</span>
          <span class="text-crewfareGreen text-right">
            {{ signedHotels }}
            <span v-if="rfp.hotel_goal && parseInt(rfp.hotel_goal) > 0">
              / {{ rfp.hotel_goal }} -
              {{
                ((signedHotels / parseInt(rfp.hotel_goal)) * 100).toFixed(1)
              }}%
            </span>
          </span>
        </div>
        <div class="w-full flex gap-4 justify-between">
          <span class="text-xs text-[#777E90] font-bold">Rooms Signed</span>
          <span class="text-crewfareGreen text-right">
            {{ signedHotelsRooms }}
            <span v-if="rfp.room_goal && parseInt(rfp.room_goal) > 0">
              / {{ rfp.room_goal }} -
              {{
                ((signedHotelsRooms / parseInt(rfp.room_goal)) * 100).toFixed(
                  1
                )
              }}%
            </span>
          </span>
        </div>
      </div>
      <RoomNumbersDialog
        v-if="showRoomNumbersDialog"
        @hideGroupDialog="showRoomNumbersDialog = false"
        :signedHotelsList="signedHotelsList"
        :group_status="group_status"
        :total="total"
      />
    </td>
    <td class="text-sm p-4 pb-6 capitalize min-w-[220px] text-xs">
      <div class="flex justify-between gap-2 relative">
        <div class="flex flex-col gap-2 justify-center">
          <span class="w-full flex gap-4 justify-between">
            <span class="text-xs text-[#777E90] font-bold">Started</span>
            <span class="text-right">
              {{ formatedCreatedAt }}
            </span>
          </span>
          <span class="w-full flex gap-4 justify-between">
            <span class="text-xs text-[#777E90] font-bold">Last update</span>
            <span class="text-right">
              {{ formatedUpdatedAt }}
            </span>
          </span>
          <span>By {{ rfp.created_by }}</span>
        </div>
        <dropdown-menu v-if="canInteract">
          <template #trigger>
            <button
              class="inline-flex relative items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              type="button"
            >
              <span class="material-symbols-rounded">more_vert</span>
            </button>
          </template>
          <template #body>
            <FwbListGroup class="absolute top-[40px] right-0 z-30 w-[240px] dark:bg-gray-700 bg-gray-700">
              <FwbListGroupItem
                v-if="rfp.status.toLowerCase() !== 'new'"
                class="!px-0 !py-0"
              >
                <router-link
                  class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  :to="{ name: 'rfpsHotels', params: { id: rfp.id } }"
                >
                  Hotels
                </router-link>
              </FwbListGroupItem>
              <FwbListGroupItem
                v-if="rfp.status.toLowerCase() !== 'new' && rfp.location"
                class="!px-0 !py-0"
              >
                <router-link
                  class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  :to="{ name: 'rfpsMap', params: { id: rfp.id } }"
                >
                  Map
                </router-link>
              </FwbListGroupItem>
              <FwbListGroupItem class="!px-0 !py-0">
                <router-link
                  class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  :to="{ name: 'rfpsForm', params: { id: rfp.id } }"
                >
                  Edit
                </router-link>
              </FwbListGroupItem>
              <FwbListGroupItem class="!px-0 !py-0">
                <router-link
                  class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  :to="{ name: 'rfpsPreview', params: { id: rfp.id } }"
                >
                  Preview RFP
                </router-link>
              </FwbListGroupItem>
              <FwbListGroupItem class="!px-0 !py-0">
                <ButtonNotesDialog
                  type="Notes on RFP"
                  :parentRFP="rfp"
                  :subtitle="rfp.name"
                  :title="`Notes at ${rfp.name}`"
                  :id="`${rfp.id}-note`"
                  text="Notes"
                />
              </FwbListGroupItem>
              <FwbListGroupItem class="!px-0 !py-0">
                <button
                  @click="duplicateRfp"
                  class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                >
                  Duplicate
                </button>
              </FwbListGroupItem>
              <FwbListGroupItem class="!px-0 !py-0">
                <button
                  v-if="rfp.status !== 'Completed'"
                  @click="completeRfp"
                  class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                >
                  Mark As Completed
                </button>
                <button
                  v-else
                  @click="restoreRfp"
                  class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                >
                  Restore RFP
                </button>
              </FwbListGroupItem>
              <FwbListGroupItem class="!px-0 !py-0">
                <span v-if="isRemoving">
                  <Spinner/>
                </span>
                <button
                  v-else
                  @click="removeRFP"
                  class="text-red-400 hover:text-red-100 flex items-center gap-2 px-4 py-2 w-full"
                >
                  Delete
                </button>
              </FwbListGroupItem>
            </FwbListGroup>
          </template>
        </dropdown-menu>
      </div>
    </td>
  </tr>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import {
  RFPStatusPill,
  sendRFP,
  restoreRfp,
  completeRfp,
  groupStatus,
} from "@/utils/rfp.jsx";
import {
  deleteDoc,
  doc,
  addDoc,
  query,
  getDoc,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";
import { RoomNumbersDialog } from "@/components/rfps/hotels";
import moment from "moment";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";
import { rfpsApi } from "@/utils/apis/rfpsApi";
import Spinner from "@/components/ui/atoms/Spinner.vue";

export default {
  components: {
    RoomNumbersDialog,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    ButtonNotesDialog,
    Spinner
  },
  props: ["rfp"],
  data() {
    return {
      showNoteDialog: false,
      showRoomNumbersDialog: false,
      signedHotelsList: [],
      signedHotels: 0,
      signedHotelsRooms: 0,
      showMenu: false,
      loading: false,
      rfp_hotels: [],
      total: 0,
      group_status: {
        signed: 0,
        accepted: 0,
        sent: 0,
        new: 0,
        declined: 0,
        negotiation: 0,
      },
      isRemoving: false
    };
  },
  computed: {
    canInteract() {
      if (this.account.type.toLowerCase() === "admin") return true;
      return (
        this.account.type.toLowerCase() === "internal" &&
        this.rfp.shared_with.includes(this.account.id)
      );
    },
    statusPill() {
      if(this.isRemoving){
        return "Removing..."
      }
      if (!this.rfp.status) return "";
      return RFPStatusPill(this.rfp.status);
    },
    account() {
      return this.$store.state.account;
    },
    formatedStartDate() {
      if (!this.rfp.startDate) return "";
      const startDate = this.rfp.startDate;
      return this.getMoment(startDate).format("ddd MMM. DD, YYYY");
    },
    formatedEndDate() {
      if (!this.rfp.endDate) return "";
      const endDate = this.rfp.endDate;
      return this.getMoment(endDate).format("ddd MMM. DD, YYYY");
    },
    formatedCreatedAt() {
      if (!this.rfp.created_at) return "";
      const created_at = this.getMoment(this.rfp.created_at);
      return created_at.fromNow();
    },
    formatedUpdatedAt() {
      if (!this.rfp.updated_at) return "";
      const updated_at = this.getMoment(this.rfp.updated_at);
      return updated_at.fromNow();
    },
  },
  async mounted() {
    this.loading = true;
    const qRFPHotel = query(
      collection(firestore, "rfp_hotel"),
      where("rfp", "==", this.rfp.id)
    );
    const RFPHotel = await getDocs(qRFPHotel);
    this.rfp_hotels = RFPHotel.docs;
    const filterHotels = this.rfp_hotels.filter(
      (rfp) =>
        rfp.data().status &&
        rfp.data().status.toLowerCase() === "signed by both sides"
    );
    let roomCount = 0;
    filterHotels.forEach((hotel) => {
      hotel
        .data()
        .roomList[0].rooms.forEach(
          (room) => (roomCount += parseInt(room.amount || 0))
        );
    });
    this.signedHotelsRooms = roomCount;
    this.signedHotelsList = filterHotels;
    this.signedHotels = filterHotels.length;
    this.total = this.rfp_hotels.length;

    this.loading = false;
  },
  methods: {
    getMoment(obj){
      return obj._seconds ? moment.unix(obj._seconds) : moment(obj.toDate());
    },
    async completeRfp() {
      await completeRfp(this.rfp);
      this.$emit("getData");
    },
    async restoreRfp() {
      await restoreRfp(this.rfp);
      this.$emit("getData");
    },
    async removeRFP() {
      if (!confirm("Delete this RFP?")) {
        return;
      }
      this.isRemoving = true;
      const deleted = await rfpsApi.remove(this.rfp.id);
      this.isRemoving = false;
      this.showMenu = false;
      if(deleted.error){
        alert(deleted.message || "Error deleting RFP");
        return;
      }
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
            <p>RFP ${this.rfp.name} has been deleted!</p>
          </div>`,
      });
      this.showMenu = false;
      this.$emit("getData");
    },
    async duplicateRfp() {
      if (!confirm("Duplicate this RFP?")) return;
      const rfpRef = doc(firestore, "rfps", this.rfp.id);
      const rfpSingle = await getDoc(rfpRef);
      const rfp = await addDoc(collection(firestore, "rfps"), {
        ...rfpSingle.data(),
        created_at: new Date(),
        created_by: this.account.name,
        updated_at: new Date(),
        name: `${rfpSingle.name} Draft`,
        status: "new",
      });
      this.showMenu = false;
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
            <p>RFP ${this.rfp.name} has been duplicated!</p>
          </div>`,
      });
      router.push({ name: "rfpsForm", params: { id: rfp.id } });
    },
    async sendRFP() {
      await sendRFP(this.rfp, this.account);
      this.showMenu = false;
      this.$emit("getData");
    },
  },
};
</script>
