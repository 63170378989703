<template>
  <div>
    <div class="flex justify-between">
      <router-link :to="{ name: 'hotelsList' }" class="flex cursor-pointer py-2 justify-between items-center">
        <span class="text-xl text-crewfareGreen flex items-center">
          <span class="material-symbols-rounded hover:text-crewfareGreen"> chevron_left </span>
          {{ $route.params.state }}
        </span>
      </router-link>
    </div>
    <div v-if="!loading" class="w-full">
      <div class="w-full flex flex-col gap-1">
        <div class="grid grid-cols-[0.25fr,1fr,0.3fr,0.5fr,1fr,0.1fr] gap-4 w-full mb-4">
          <div class="invisible h-0"></div>
          <div class="flex items-center gap-2 cursor-pointer" v-for="item in rowHeaders" :key="item.name" @click="updateSortBy(item.value)">
            <div>{{ item.name }}</div>
            <img src="/icons/i-arrow-down.svg" class="w-4 h-4" :class="{'rotate-180': sortBy === item.value && sortDirection === 'desc'}" />
          </div>
          <div class="invisible h-0"></div>
        </div>
        <HotelRow v-for="hotel in hotels" :hotel="hotel" :key="hotel.id" @editHotel="editHotel" @remove-hotel="removeHotel" />
      </div>
    </div>
    <div v-else class="w-full">
      <div class="w-full min-h-[120px] flex items-center justify-center">
        <span class="material-symbols-rounded animate-spin text-[26px]"> autorenew </span>
      </div>
    </div>
    <Pagination :actual="actual" :max="perPage" :total="countTotal" @togglePage="togglePage" />
  </div>
</template>

<script>
import Pagination from '@/components/default/Pagination.vue';
import { Filters, ListItem, ListItemSkeleton } from '@/components/hotels';
import HotelRow from '@/components/ui/molecules/HotelRow.vue';
import { hotelsApi } from '@/utils/apis/hotelsApi';
import { debounce } from '@/utils/formatter';

export default {
  props: ['stateList', 'filteredHotels', 'list', 'search'],
  data() {
    return {
      loading: true,
      actual: 0,
      countTotal: 0,
      perPage: 20,
      hotels: [],
      searchCount: 0,
      rowHeaders: [
        {
          name: 'Name',
          value: 'name',
        },
        {
          name: 'Chain',
          value: 'chain',
        },
        {
          name: 'Star Rating',
          value: 'rating',
        },
        {
          name: 'Contact',
          value: 'contacts',
        },
      ],
      sortBy: 'name',
      sortDirection: 'asc',
    };
  },
  components: {
    ListItem,
    ListItemSkeleton,
    Filters,
    Pagination,
    HotelRow,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    lastUploaded(){
      return this.$store.state.lastUploaded;
    }
  },
  watch: {
    search: debounce(function () {
      this.actual = 0;
      this.getData();
    }, 1000),
    lastUploaded(){
      this.actual = 0;
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    togglePage(page) {
      this.actual = page;
      this.getData();
    },
    parseData(list) {
      const hotelsList = [];
      list.forEach(hotel => {
        hotelsList.push({
          id: hotel.id,
          name: hotel.data().name,
          contacts: hotel.data().contacts,
          status_history: hotel.data().status_history,
          isPartner: hotel.data().isPartner,
          rooms: hotel.data().rooms,
          room_goal: hotel.data().room_goal,
          chain: hotel.data().chain,
        });
      });
      return hotelsList;
    },
    async getData() {
      this.loading = true;
      this.searchCount++;
      this.searchHotels(this.searchCount);
    },
    async searchHotels(currentCount) {
      let hotels = await hotelsApi
        .list({ search: this.search, state: this.$route.params.state, page: this.actual, sortBy: this.sortBy, sortDirection: this.sortDirection, per_page: this.perPage })
        .then(_ => _.data);
      if (currentCount !== this.searchCount) {
        console.log('Data is stale');
        return;
      }
      this.countTotal = hotels.total;
      this.hotels = hotels.data;
      this.loading = false;
    },

    editHotel(hotelId) {
      this.$emit('editHotel', hotelId);
      this.getData();
    },
    async removeHotel(hotelId) {
      await hotelsApi.remove(hotelId);
      this.getData();
    },

    updateSortBy(sortBy) {
      if (sortBy === this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = sortBy;
        this.sortDirection = 'asc';
      }
      this.getData();
    },
  },
};
</script>

