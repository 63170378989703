<template>
  <div class="flex flex-col gap-4">
    <div v-if="title" class="text-white text-base font-medium">{{ title }}</div>
    <div :class="currentClass">
      <img
        v-for="index in 5"
        :key="index"
        :class="{ 'cursor-pointer': !readOnly }"
        :style="{ opacity: index <= currentRating ? 1 : 0.5 }"
        src="/icons/i-star.svg"
        @click="!readOnly && updateRating(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    title: String,
    modelValue: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modelRating: this.modelValue,
    };
  },

  emits: ['update:modelValue'],

  computed: {
    currentRating() {
      return this.modelValue;
    },
    currentClass() {
      return {
        'flex gap-2 ': this.readOnly,
        'flex gap-2 px-4 py-4 border border border-crewfareGrey rounded-lg': !this.readOnly,
      };
    },
  },

  methods: {
    updateRating(rating: number) {
      this.modelRating = rating;
      this.$emit('update:modelValue', rating);
    },
  },
};
</script>
